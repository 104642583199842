import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { UserContext } from "context/UserContext";
import { FilterContext } from "context/FilterContext";
import { getCandidateProfileData } from "services/FetchApiData";
import { cardViewText } from "helpers/Localization";
import ModalBox from "components/Modal/ModalBox";
import { calculateAge } from "helpers/ReusableFunctions";
import ProfileModalHeader from "components/CandidateProfileModalContent/ProfileModalHeader";
import ProfileModalBody from "components/CandidateProfileModalContent/ProfileModalBody";

const CandidateDirectAccess = () => {
  const { language } = useContext(UserContext);
  const { filterStore, candidateAccess, setLoading, loading } =
    useContext(FilterContext);

  const [candidateBasicData, setCandidateBasicData] = useState({});
  const [showModal, setShowModal] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const handleShowModal = () => {
    setShowModal((current) => !current);
    navigate(candidateAccess?.openedTab ? -1 : "/");
  };

  const fetchCandidateBasicData = useCallback(async () => {
    if (!params?.candidate_id) return;
    try {
      setLoading(true);
      const res = await getCandidateProfileData(params?.candidate_id);
      if (!res.data?.data?.candidates?.profile) {
        handleShowModal();
      } else {
        setCandidateBasicData(res.data.data.candidates.profile);
        setShowModal(true);
      }
    } catch (error) {
      console.log(error);
      handleShowModal();
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.candidate_id]);

  useEffect(() => {
    fetchCandidateBasicData();
    return () => setCandidateBasicData({});
  }, [fetchCandidateBasicData]);

  if (!params?.candidate_id || loading) {
    return <></>;
  }
  return (
    <>
      <ModalBox
        show={showModal}
        onHide={handleShowModal}
        content={
          <CandidateProfileModalContent
            closeModal={handleShowModal}
            currentData={candidateBasicData}
            title={cardViewText.profileView[language]}
            showSocialProfile={!["maybe"].includes(filterStore.PitchResponse)}
          />
        }
      />
    </>
  );
};

export default CandidateDirectAccess;

const CandidateProfileModalContent = ({
  closeModal = () => {},
  currentData = {},
  title = "",
}) => {
  function extractCandidateBasicData(candidate) {
    return {
      age: +calculateAge(candidate?.birthday),
      ...JSON.parse(JSON.stringify(candidate)),
    };
  }

  return (
    <div>
      <ProfileModalHeader
        title={title}
        closeModal={closeModal}
        currentData={currentData}
        isCandidatesAccess
      />
      <ProfileModalBody
        data={extractCandidateBasicData(currentData)}
        pageNo={0}
        isInternalUser
        isCandidatesAccess
      />
    </div>
  );
};
