import React, {
  useEffect,
  useRef,
  forwardRef,
  useContext,
  useCallback,
  useState,
} from "react";
import { Row } from "react-bootstrap";

import HistoryDetailList from "./HistoryDetailList";
import { formatDate, convertStageCodeToName } from "helpers/ReusableFunctions";
import { UserContext } from "context/UserContext";
import AccordionComp from "components/UI/Accordion/AccordionComp";
import { getPositionHistory } from "services/FetchApiData";
import { StageHistory } from ".";

const ActiveApplicationsHistoryDetail = ({
  data = {},
  isInternalUser = false,
  scrollToDetailSectionId = "",
  setScrollToDetailSectionId = () => {},
  activeApplicationHistory,
}) => {
  const divRefs = useRef([]);

  useEffect(() => {
    divRefs.current[scrollToDetailSectionId]?.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });

    return () => setScrollToDetailSectionId("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToDetailSectionId]);

  return activeApplicationHistory.length > 0 ? (
    <>
      <p className="profile_section-title">
        Active Applications History Details
      </p>
      {activeApplicationHistory.map((hist, i) => (
        <ActiveApplicationHistory
          history={hist}
          key={i}
          isInternalUser={isInternalUser}
          ref={(element) => {
            divRefs.current[hist?.id] = element;
          }}
        />
      ))}
    </>
  ) : (
    <></>
  );
};

const ActiveApplicationHistory = forwardRef((props, ref) => {
  const { language } = useContext(UserContext);
  const { history: current = {}, isInternalUser } = props;

  const [loading, setLoading] = useState(false);
  const [positionHistory, setPositionHistory] = useState([]);

  const fetchCandidatePositionHistory = useCallback(
    async (position_id) => {
      if (!position_id) return;
      try {
        setLoading(true);
        const resonse = await getPositionHistory(
          current?.candidate_id,
          position_id
        );
        setPositionHistory(
          resonse.data.data.pipelines.candidate_position_history.data || []
        );
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [current?.candidate_id]
  );

  const handleToggle = useCallback(
    (positionId) => {
      fetchCandidatePositionHistory(positionId);
    },
    [fetchCandidatePositionHistory]
  );

  return (
    <div className="mt-3" ref={ref}>
      <div className="card">
        <div className="card-body">
          <Row>
            <HistoryDetailList title={"Company"} value={current.text} />
            {isInternalUser && (
              <HistoryDetailList
                title={"Current Stage"}
                value={convertStageCodeToName(
                  current?.stage,
                  current?.pitch_response?.response,
                  language,
                  <span className="text-center">Unknown</span>
                )}
              />
            )}
          </Row>
          <hr />

          <Row>
            <HistoryDetailList
              title={"Date"}
              value={formatDate(current?.created_at)}
            />
          </Row>
          <hr />
          <Row>
            <AccordionComp
              id={current?.position_id}
              eventKey={current?.position_id}
              handleToggle={handleToggle}
              accordionBody={
                positionHistory?.length > 0 ? (
                  <StageHistory
                    stageHistory={positionHistory}
                    isInternalUser={isInternalUser}
                  />
                ) : (
                  <p className="p-2 text-muted">No Stage History Found!</p>
                )
              }
              minimizeText="Minimize Details"
              expandText="Expand Details"
              isClientSide={false}
              loading={loading}
            />
          </Row>
        </div>
      </div>
    </div>
  );
});

export default ActiveApplicationsHistoryDetail;
export { ActiveApplicationHistory };
