export const sidebarText = {
  position: {
    en: "Position",
    ja: "職種",
  },
  specialization: {
    en: "Position Specialization",
    ja: "専門分野",
  },
  startupEnterprise: {
    en: "Startup or Enterprise",
    ja: "企業規模",
  },
};
export const dashboardText = {
  lineChartTitle: {
    en: "Employer Branding Report Time Comparison",
    ja: "エンプロイヤーブランディング　レポート",
  },
  barChartTitle: {
    en: "Employer Branding Report",
    ja: "エンプロイヤーブランディング　レポート",
  },
  goToReports: {
    en: "Go To Reports",
    ja: "レポートへ",
  },
  filter: {
    en: "Filters",
    ja: "フィルター",
  },
  minimize: {
    en: "Minimize",
    ja: "縮小する",
  },
  dashboard: {
    en: "Dashboard",
    ja: "ダッシュボード",
  },
  users: {
    en: "Users",
    ja: "ユーザー",
  },
  reports: {
    en: "Reports",
    ja: "レポート",
  },
  jobs: {
    en: "Jobs",
    ja: "Jobs",
  },
  candidates: {
    en: "Candidates",
    ja: "Candidates",
  },
};

export const performanceReportText = {
  employerBrandingReportComparisons: {
    en: "Employer Branding Report Comparisons",
    ja: "エンプロイヤー・ブランディング・レポート比較",
  },
  backToList: {
    en: "Back to List of Reports",
    ja: "リポートリストへ",
  },
  lineChartTitle: {
    en: "Employer Branding Report",
    ja: "エンプロイヤーブランディング　レポート",
  },
  applicants: {
    en: " Applicants",
    ja: "と回答した候補者",
  },
  getAll: {
    en: "Get All",
    ja: "リセット",
  },
  export: {
    en: "Export",
    ja: "エクスポート",
  },
  saveAs: {
    en: "Save as",
    ja: "保存する",
  },
  yourApplicationRate: {
    en: "Your Application Rate",
    ja: "貴社への応募率",
  },
  totalMarketAvg: {
    en: "Total Market Average",
    ja: "マーケットの平均応募率",
  },
  industryAvg: {
    en: "Your Industry Average",
    ja: "業界の平均応募率",
  },
  industry: {
    en: "Industry",
    ja: "業界",
  },
  errorMessage: {
    yes: {
      en: "Any data prior to March 1, 2023 may be unavailable or inaccurate.",
      ja: "2023年3月1日以前のデータは入手不可能または不正確である可能性があります",
    },
    no: {
      en: "Any data prior to June 1, 2023 may be unavailable or inaccurate.",
      ja: "2023年6月1日以前のデータは入手不可能または不正確である可能性があります",
    },
    maybe: {
      en: "Any data prior to March 1, 2023 may be unavailable or inaccurate.",
      ja: "2023年3月1日以前のデータは入手不可能または不正確である可能性があります",
    },
  },
  detailedBreakdown: {
    en: "Detailed Breakdown of Applicants",
    ja: "応募者の詳細",
  },
  applicantDetails: {
    yes: {
      en: "Applicant Details",
      ja: "YESの応募者の詳細",
    },
    no: {
      en: "Applicant Details",
      ja: "NOと回答をした応募者の詳細",
    },
    maybe: {
      en: "Applicant Details",
      ja: "MAYBEと回答をした応募者の詳細",
    },
  },
  id: {
    en: "ID Number",
    ja: "ID番号",
  },
  firstName: {
    en: "First Name",
    ja: "名（英語）",
  },
  lastName: {
    en: "Last Name",
    ja: "姓（英語）",
  },
  employer: {
    en: "Employer",
    ja: "会社名",
  },
  title: {
    en: "Title",
    ja: "タイトル",
  },
  initialResponse: {
    en: "Initial Response",
    ja: "回答",
  },
  salary: {
    en: "Salary",
    ja: "年収",
  },
  salaryBreakdown: {
    en: "Salary Breakdown",
    ja: "年収の内訳",
  },
  english: {
    en: "English",
    ja: "英語",
  },
  japanese: {
    en: "Japanese",
    ja: "日本語",
  },
  firstNameJ: {
    en: "First Name (J)",
    ja: "名",
  },
  lastNameJ: {
    en: "Last Name (J)",
    ja: "姓",
  },
  linkedinUrl: {
    en: "Linkedin",
    ja: "LinkedIn",
  },
  twitterUrl: {
    en: "X/Twitter",
    ja: "X/Twitter",
  },
  facebookUrl: {
    en: "Facebook",
    ja: "Facebook",
  },
  githubUrl: {
    en: "Github",
    ja: "Github",
  },
  age: {
    en: "Age",
    ja: "年齢",
  },
  educationHistory: {
    en: "Education",
    ja: "学歴",
  },
  workHistory: {
    en: "Work History",
    ja: "職歴",
  },
  reasonsForNo: {
    en: "- Reasons for No",
    ja: "- NOの理由",
  },
};
export const barChartText = {
  applicantRates: {
    en: "Applicant Rates",
    ja: "応募率",
  },
  reason: {
    en: "Reason",
    ja: "理由：",
  },
  // reasons for no
  badCompanyImpression: {
    en: "Bad Company Impression",
    ja: "企業への悪い印象",
  },
  notInterestedRole: {
    en: "Not Interested Role",
    ja: "ポジションに対して興味が無い",
  },
  notInterestedTechStack: {
    en: "Not Interested Tech Stack",
    ja: "テック・スタックに対して興味が無い",
  },
  notLooking: {
    en: "Not Looking",
    ja: "転職を考えていない",
  },
  prefersOtherCompany: {
    en: "Prefers Other Company",
    ja: "他社を優先したい",
  },
  salaryConcern: {
    en: "Salary Concern",
    ja: "年収の懸念",
  },
  workingLanguageConcern: {
    en: "Working Language Concern",
    ja: "使用言語の懸念",
  },
  // line labels
  company: {
    en: "Your Company",
    ja: "貴社",
  },
  market: {
    en: "Market",
    ja: "マーケットの平均",
  },
  industry: {
    en: "Industry",
    ja: "業界の平均",
  },
};
export const cardViewText = {
  profileView: {
    en: "Profile View",
    ja: "プロフィール",
  },
  age: {
    en: "Age",
    ja: "年齢：",
  },
  salary: {
    en: "Salary",
    ja: "年収",
  },
  salaryBreakdown: {
    en: "Salary Breakdown",
    ja: "年収の詳細",
  },
  languages: {
    en: "Languages",
    ja: "言語",
  },
  position: {
    en: "Position",
    ja: "ポジション",
  },
  applicationStatus: {
    en: "Application Status",
    ja: "応募ステータス",
  },
  workHistory: {
    en: "Work History",
    ja: "職歴",
  },
  education: {
    en: "Education",
    ja: "学歴",
  },
  applicationHistory: {
    en: "Application History",
    ja: "応募歴",
  },
  notes: {
    en: "Notes",
    ja: "Notes",
  },
  otherApplications: {
    en: "Other Applications",
    ja: "Other Applications",
  },
  //status
  pitching: {
    text: "pitching",
    en: "Pitching",
    ja: "提案中",
  },
  waiting: {
    text: "waiting",
    en: "Waiting",
    ja: "Waiting",
  },
  ok_to_send: {
    text: "ok_to_send",
    en: "Ready for Introduction",
    ja: "応募OK",
  },
  introduced: {
    text: "introduced",
    en: "Resume Screening",
    ja: "レジュメ検査",
  },
  coding_test: {
    text: "coding_test",
    en: "Test",
    ja: "テスト",
  },
  ccm_1: {
    text: "ccm_1",
    en: "1st Interview",
    ja: "第一面接",
  },
  ccm_2: {
    text: "ccm_2",
    en: "2nd Interview",
    ja: "第二面接",
  },
  ccm_3: {
    text: "ccm_3",
    en: "3rd Interview",
    ja: "第三面接",
  },
  ccm_4_plus: {
    text: "ccm_4_plus",
    en: "4th Interview+",
    ja: "第四以上面接",
  },
  final: {
    text: "final",
    en: "Final Interview",
    ja: "最終面接",
  },
  offer: {
    text: "offer",
    en: "Offer Extended",
    ja: "オファー",
  },
  offer_accepted: {
    text: "offer_accepted",
    en: "Offer Accepted",
    ja: "オファー承諾",
  },
  offer_declined: {
    text: "offer_declined",
    en: "Offer Declined",
    ja: "オファー拒否",
  },
  esai_rejected: {
    text: "esai_rejected",
    en: "ESAI Rejected",
    ja: "ESAIによるお見送り",
  },
  client_rejected: {
    text: "client_rejected",
    en: "Rejected by Company",
    ja: "貴社によるお見送り",
  },
  candidate_withdrew: {
    text: "candidate_withdrew",
    en: "Candidate Withdrew",
    ja: "応募者による辞退",
  },
  candidate_ghosted: {
    text: "candidate_ghosted",
    en: "Candidate Ghosted",
    ja: "Candidate Ghosted",
  },
};
export const timelineText = {
  none: {
    en: "None",
    ja: "None",
  },
  compareWith: {
    en: "Compare with",
    ja: "間の比較",
  },
  month: {
    en: "Month",
    ja: "カ月",
  },
  quarter: {
    en: "Quarter",
    ja: "四半期",
  },
  year: {
    en: "Year",
    ja: "年",
  },
  custom: {
    en: "Period",
    ja: "Period",
  },
  enterNumber: {
    en: "Enter a different number",
    ja: "別の番号を入力",
  },
  customRange: {
    en: "Custom date range",
    ja: "Custom date range",
  },
};
export const gridText = {
  sortbyAsc: {
    en: "Sort by ASC",
    ja: "昇順で並び替え",
  },
  sortbyDesc: {
    en: "Sort by DESC",
    ja: "降順で並び替え",
  },
  pinToLeft: {
    en: "Pin to left",
    ja: "左側へピン留め",
  },
  pinToRight: {
    en: "Pin to right",
    ja: "右側へピン留め",
  },
  filter: {
    en: "Filter",
    ja: "フィルター",
  },
  hideColumn: {
    en: "Hide column",
    ja: "コラムを非表示",
  },
  sowColumn: {
    en: "Show column",
    ja: "列を表示",
  },
  manageColumns: {
    en: "Manage columns",
    ja: "コラムを管理",
  },
  unpin: {
    en: "Unpin",
    ja: "ピン留めを解除",
  },
  hideAll: {
    en: "Hide ALL",
    ja: "全て非表示",
  },
  showAll: {
    en: "Show All",
    ja: "全て閲覧",
  },
};
