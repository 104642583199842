import React, { useContext, useEffect, useState } from "react";
import {
  DataGridPro,
  getGridNumericOperators,
  getGridStringOperators,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import ProfileModalContent from "components/CandidateProfileModalContent/ProfileModalContent";
import FilterNav from "components/FilterNav/FilterNav";
import ModalBox from "components/Modal/ModalBox";
import CustomFilter from "components/Reports/CustomFilter";

import { UserContext } from "context/UserContext";
import {
  cardViewText,
  dashboardText,
  performanceReportText,
} from "helpers/Localization";
import {
  convertGridColumnMenu,
  getCurrentPageAllFiltersFromFilterStore,
} from "helpers/ReusableFunctions";
import ExportModal from "pages/PipelineReport/components/ExportModal";
import { FilterContext } from "context/FilterContext";
import { getCandidateList } from "services/FetchApiData";
import { PAGES } from "helpers/Constant";
import "./CandidateList.scss";
import moment from "moment";

const CandidateList = () => {
  const apiRef = useGridApiRef();
  const { language, userGroup, loggedInUserData } = useContext(UserContext);
  const {
    filterStore,
    applyFilterFlags: { applyFilterInJobs },
    setLastAppliedFilterstore,
    setIsDisableApplyButton,
    isInternalUser,
  } = useContext(FilterContext);

  const sortableColumn = true;
  const pinnableColumn = true;

  const filterOperators = getGridStringOperators().filter(
    ({ value }) => !["isEmpty", "isNotEmpty", "isAnyOf"].includes(value)
  );

  const columns = [
    {
      field: "first_name",
      headerName: "First Name (English)",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "last_name",
      headerName: "Last Name (English)",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "kanji_last",
      headerName: "Last Name (Japanese)",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "kanji_first",
      headerName: "First Name (Japanese)",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "tags",
      headerName: "Tags",
      width: 300,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      valueGetter: ({ value }) =>
        value?.map((el) => el[0].toUpperCase() + el.slice(1))?.join(", "),
    },
    {
      field: "current_title",
      headerName: "Title",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "current_employer",
      headerName: "Company Name",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "background",
      headerName: "Background",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "work_history",
      headerName: "Work History",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "education_history",
      headerName: "Education History",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "bio",
      headerName: "Bio",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "english_level_str",
      headerName: "English level",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "japanese_level_str",
      headerName: "Japanese level",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "linkedin_url",
      headerName: "Linkedin",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "twitter_url",
      headerName: "Twitter",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "facebook_url",
      headerName: "Facebook",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "github_url",
      headerName: "Github",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "outreach_url",
      headerName: "Outreach",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "zoho_url",
      headerName: "Zoho",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "trello_url",
      headerName: "Trello",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "certifications",
      headerName: "Certifications",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "skills",
      headerName: "Skills",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "salary",
      headerName: "Salary",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "salary_breakdown",
      headerName: "Salary Breakdown",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "english_level_num",
      headerName: "English Level (1-10)",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "japanese_level_num",
      headerName: "Japanese Level (1-10)",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
  ];

  const [candidateListData, setCandidateListData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 100, offset: 0 });
  const [showExportModal, setShowExportModal] = useState(false);
  const [initialRenderFlag, setInitialRenderFlag] = useState(true);
  const [isLoadingTableData, setIsLoadingTableData] = useState(false);
  const [columnsOrder, setColumnsOrder] = useState(columns);
  const [hiddenColumns, setHiddenColumns] = useState();
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const columnsWithOperators = columnsOrder.map((col) => {
    if (col.type === "number") {
      return {
        ...col,
        filterOperators: [
          ...getGridNumericOperators()
            .filter((operator) => {
              if (operator.value === "=") operator.label = "equals";
              if (operator.value === ">") operator.label = "greater than";
              if (operator.value === "<") operator.label = "less than";
              return (
                operator.value === ">" ||
                operator.value === "<" ||
                operator.value === "="
              );
            })
            .map((operator) => ({
              ...operator,
              InputComponent: CustomFilter,
            })),
        ],
      };
    }

    const customFilterOperators = getGridStringOperators().map((operator) => ({
      ...operator,
      InputComponent: CustomFilter,
    }));

    return {
      ...col,
      filterOperators: customFilterOperators,
    };
  });

  useEffect(() => {
    setInitialRenderFlag(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!initialRenderFlag) {
      fetchCandidateListData(filterStore);
    }
    return () => setCandidateListData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyFilterInJobs, initialRenderFlag, language, pagination?.offset]);

  const fetchCandidateListData = async (fs) => {
    try {
      setIsLoadingTableData(true);
      setIsDisableApplyButton(true);
      const response = await getCandidateList(
        getCurrentPageAllFiltersFromFilterStore(
          fs,
          isInternalUser,
          PAGES.CANDIDATES
        ),
        pagination,
        language
      );
      setCandidateListData(
        [
          ...candidateListData,
          ...(response?.data?.data?.candidates?.list?.data ?? []),
        ]?.map((el) => ({
          ...el,
          id: el?.candidate_id,
          age: el?.birthday ? moment().diff(el?.birthday, "years") : "",
        }))
      );
      setPagination({
        ...pagination,
        count: response?.data?.data?.candidates?.list?.count || 0,
        hasMore: response?.data?.data?.candidates?.list?.has_more,
      });
      setLastAppliedFilterstore(fs);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingTableData(false);
      setIsDisableApplyButton(false);
    }
  };

  const handleShowExportModal = () => {
    setShowExportModal((current) => !current);
  };

  const handleShowProfileModal = (data) => {
    setSelectedRow(data);
    setShowProfileModal(true);
  };

  const handleCloseModal = () => {
    setShowProfileModal((current) => !current);
  };

  const handlePaginationModelChange = ({ page }) => {
    setPagination({
      ...pagination,
      offset: pagination?.limit * page,
    });
  };

  return (
    <div className="candidates">
      <h4>{dashboardText.candidates[language]}</h4>
      <FilterNav />
      <div className="card mt-4">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <h5 className="flex-shrink-1">List of All Candidates</h5>
            <div className="d-flex gap-0 flex-shrink-0">
              {(userGroup.includes("external") ||
                (userGroup.includes("internal") &&
                  loggedInUserData.is_admin)) && (
                <div
                  className="action-button align-self-center"
                  onClick={handleShowExportModal}
                >
                  <div className="m-0">
                    {performanceReportText.export[language]}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className="cstm-mui-datagrid"
            style={{ height: 500, width: "100%" }}
          >
            <DataGridPro
              loading={isLoadingTableData}
              rows={candidateListData}
              rowCount={pagination?.count}
              pagination
              pageSizeOptions={[pagination?.limit || 0]}
              onPaginationModelChange={handlePaginationModelChange}
              columns={columnsWithOperators}
              slotProps={{
                filterPanel: {
                  sx: { maxWidth: "calc(90vw - 24px)" },
                },
                pagination: {
                  backIconButtonProps: {
                    disabled: isLoadingTableData || !pagination?.offset,
                  },
                  nextIconButtonProps: {
                    disabled: isLoadingTableData || !pagination?.hasMore,
                  },
                },
              }}
              getRowId={(row) => row?.id}
              onColumnOrderChange={(c) => {
                setColumnsOrder((prevCols) => {
                  const newCols = [...prevCols];
                  newCols.splice(c.oldIndex, 1);
                  newCols.splice(c.targetIndex, 0, c.column);
                  return newCols;
                });
              }}
              initialState={{
                ...columns.initialState,
                columns: columns.initialState?.columns,
              }}
              onRowClick={(params) => handleShowProfileModal(params.row)}
              localeText={convertGridColumnMenu(language)}
              apiRef={apiRef}
              onColumnVisibilityModelChange={(hideColumns) => {
                const hideColsName = Object.entries(hideColumns)
                  .filter(([_key, value]) => value === false)
                  ?.map((col) => col[0]);
                setHiddenColumns(hideColsName);
              }}
              rowsLoadingMode={"server"}
            />
          </div>
        </div>
      </div>
      <ModalBox
        show={showExportModal}
        onHide={handleShowExportModal}
        title="Export File"
        content={
          <ExportModal
            fileName="Candidate List"
            setShowModal={setShowExportModal}
            columns={columnsOrder}
            apiRef={apiRef}
            hiddenColumns={hiddenColumns}
          />
        }
      />
      <ModalBox
        show={showProfileModal}
        onHide={handleCloseModal}
        content={
          apiRef?.current?.state && (
            <ProfileModalContent
              closeModal={handleCloseModal}
              current={selectedRow}
              title={cardViewText.profileView[language]}
              apiRef={apiRef}
            />
          )
        }
      />
    </div>
  );
};

export default CandidateList;
