import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";

import {
  DataGridPro,
  getGridNumericOperators,
  getGridStringOperators,
  useGridApiRef,
} from "@mui/x-data-grid-pro";

import ExportModal from "pages/PipelineReport/components/ExportModal";
import ModalBox from "components/Modal/ModalBox";

import { FilterContext } from "context/FilterContext";
import { UserContext } from "context/UserContext";
import {
  barChartText,
  cardViewText,
  performanceReportText,
} from "helpers/Localization";
import {
  ChartsColor,
  FILTER_TYPES,
  PAGES,
  PitchDeclinedReasonChoices,
  PitchResponseChoices,
  pieChartNameConvention,
} from "helpers/Constant";
import {
  calculateAge,
  convertGridColumnMenu,
  getColumnsAfterChangeOrder,
  orderArrayOfObjectSimilarToRefArrayOfKeys,
  reformatString,
  getCurrentPageAllFiltersFromFilterStore,
  convertStageCodeToName,
  formatDate,
} from "helpers/ReusableFunctions";
import CandidateProfileView from "components/CandidateProfileModalContent/ProfileModalContent";
import CustomFilter from "components/Reports/CustomFilter";
import SaveAsModal from "pages/PipelineReport/components/SaveAsModal";
import { getDetailedApplicationData } from "services/FetchApiData";

const ApplicantDetailsTable = ({
  handleSetLocationUsed = () => {},
  locationStateUsed,
  getAllFlagC = false,
  setGetAllFlags,
}) => {
  const {
    filterStore,
    applyFilterFlags: { applyFilterInEmployeeBranding },
    setLastAppliedFilterstore,
    initialFilterStore,
    setFilterStore,
    Clients,
    setIsDisableApplyButton,
    overrideFilterStoreWithSavedFilters = () => {},
    isPresentationEnabled,
    filterStoreToPassWhenTopFiltersChange,
    isInternalUser,
  } = useContext(FilterContext);

  const { language, userGroup, loggedInUserData } = useContext(UserContext);

  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showSaveAasModal, setShowSaveAsModal] = useState(false);
  const [initialRenderFlag, setInitialRenderFlag] = useState(true);
  const [isTableLoad, setIsTableLoad] = useState(false);

  const apiRef = useGridApiRef();
  const location = useLocation();
  const state = location.state;

  //? uncomment : if on refresh you don't require custom report view.
  // window.history.replaceState({}, document.title);

  const sortableColumn = true;
  const pinnableColumn = true;

  const handleShowProfileModal = () => {
    setShowProfileModal((current) => !current);
  };

  const handleShowExportModal = () => {
    setShowExportModal((current) => !current);
  };

  const handleShowSaveAsModal = () => {
    setShowSaveAsModal((current) => !current);
  };

  const customStageColumnSorting = (v1, v2, ...rest) => {
    let row1_id = rest[0].id;
    let row2_id = rest[1].id;

    let row1 = rest[0].api.getRow(row1_id);
    let row2 = rest[1].api.getRow(row2_id);

    let intV1 = v1 !== "New Candidate" ? +row1?.[rest[0].field] : 0;
    let intV2 = v2 !== "New Candidate" ? +row2?.[rest[0].field] : 0;
    return intV1 - intV2;
  };

  const filterOperators = getGridStringOperators().filter(
    ({ value }) => !["isEmpty", "isNotEmpty", "isAnyOf"].includes(value)
  );

  const ReportsData = tableData?.map((user, index) => ({
    id: index + 1,
    age: +calculateAge(user.birthday),
    // workhistory: stringSplit(user, 'prospects.workhistory'),
    initial_response: reformatString(
      PitchResponseChoices[user.app_position.pitch_response.response]
    ),
    response_reason: reformatString(
      PitchDeclinedReasonChoices[user.app_position.pitch_response.reason]
    ),
    current_stage: user.app_position.current_stage,
    last_activity_date: new Date(user.last_activity_date),
    ...user,
  }));

  const japaneseName = useMemo(
    () => [
      {
        field: "kanji_last",
        headerName: performanceReportText.lastNameJ[language],
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "kanji_first",
        headerName: performanceReportText.firstNameJ[language],
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language]
  );

  const englishName = useMemo(
    () => [
      {
        field: "first_name",
        headerName: performanceReportText.firstName[language],
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "last_name",
        headerName: performanceReportText.lastName[language],
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language]
  );

  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: performanceReportText.id[language],
        width: 30,
      },
      ...(!["no", "maybe"].includes(filterStore.PitchResponse)
        ? language === "ja"
          ? japaneseName
          : englishName
        : []),
      {
        field: "current_employer",
        headerName: performanceReportText.employer[language],
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "current_title",
        headerName: performanceReportText.title[language],
        width: 200,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        type: "string",
        field: "current_stage",
        headerName: "Latest Stage",
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
        valueGetter: ({ row }) => {
          return convertStageCodeToName(
            row?.current_stage,
            row?.app_position?.pitch_response.response,
            language
          );
        },

        sortComparator: (v1, v2) => {
          const intV1 = customStageColumnSorting;
          const intV2 = customStageColumnSorting;
          return intV1 - intV2;
        },
      },
      {
        field: "last_activity_date",
        headerName: "Date of Last Activity",
        width: 200,
        sortable: sortableColumn,
        pinnableColumn: pinnableColumn,
        filterOperators: filterOperators,
        renderCell: ({ value }) => {
          return formatDate(value);
        },
      },
      ...(!["maybe"].includes(filterStore.PitchResponse)
        ? [
            {
              field: "initial_response",
              headerName: performanceReportText.initialResponse[language],
              width: 200,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
            },
          ]
        : []),
      ...(["no"].includes(filterStore.PitchResponse)
        ? [
            {
              field: "response_reason",
              headerName: barChartText.reason[language],
              width: 200,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
            },
          ]
        : []),
      {
        field: "salary",
        headerName: performanceReportText.salary[language],
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
        type: "number",
        align: "left",
        headerAlign: "left",
        // valueGetter: (params) => Number(params.value),
      },
      {
        field: "salary_breakdown",
        headerName: performanceReportText.salaryBreakdown[language],
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "english",
        headerName: performanceReportText.english[language],
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
        type: "number",
        align: "left",
        headerAlign: "left",
        // valueGetter: (params) => Number(params.value),
      },
      {
        field: "japanese",
        headerName: performanceReportText.japanese[language],
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
        type: "number",
        align: "left",
        headerAlign: "left",
        // valueGetter: (params) => {
        //   if (isNaN(params.value)) {
        //     return params.value;
        //   }
        //   return Number(params.value);
        // },
      },
      ...(!["no", "maybe"].includes(filterStore.PitchResponse)
        ? [
            ...(language === "en" ? japaneseName : englishName),
            {
              field: "linkedin_url",
              headerName: performanceReportText.linkedinUrl[language],
              width: 150,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
              renderCell: renderSocialLinksActions,
            },
            {
              field: "twitter_url",
              headerName: performanceReportText.twitterUrl[language],
              width: 150,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
              renderCell: renderSocialLinksActions,
            },
            {
              field: "facebook_url",
              headerName: performanceReportText.facebookUrl[language],
              width: 150,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
              renderCell: renderSocialLinksActions,
            },
            {
              field: "github_url",
              headerName: performanceReportText.githubUrl[language],
              width: 150,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
              renderCell: renderSocialLinksActions,
            },
          ]
        : []),
      {
        field: "age",
        headerName: performanceReportText.age[language],
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "education_history",
        headerName: performanceReportText.educationHistory[language],
        width: 200,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "work_history",
        headerName: performanceReportText.workHistory[language],
        width: 500,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [englishName, filterStore.PitchResponse, japaneseName]
  );

  const initialHiddenColumns = {
    id: false,
  };

  function renderSocialLinksActions(params) {
    return (
      <Link to={params.value} target="_blank" rel="noreferrer">
        {params.value}
      </Link>
    );
  }

  const [columnsOrder, setColumnsOrder] = useState(
    columns?.filter((col) => !initialHiddenColumns[col.field])
  );

  useEffect(() => {
    setColumnsOrder(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStore.PitchResponse]);

  const [hiddenColumns, setHiddenColumns] = useState(
    Object.keys(initialHiddenColumns)
  );

  const columnsWithOperators = columnsOrder.map((col) => {
    if (col.type === "number") {
      return {
        ...col,
        filterOperators: [
          ...getGridNumericOperators()
            .filter((operator) => {
              if (operator.value === "=") operator.label = "equals";
              if (operator.value === ">") operator.label = "greater than";
              if (operator.value === "<") operator.label = "less than";
              return (
                operator.value === ">" ||
                operator.value === "<" ||
                operator.value === "="
              );
            })
            .map((operator) => ({
              ...operator,
              InputComponent: CustomFilter,
            })),
        ],
      };
    }

    const customFilterOpeartors = getGridStringOperators().map((operator) => ({
      ...operator,
      InputComponent: CustomFilter,
    }));

    return {
      ...col,
      filterOperators: customFilterOpeartors,
    };
  });

  const getChartName = () => {
    if (filterStore?.PitchResponse) {
      const index = pieChartNameConvention.findIndex(
        (item) => item.value === filterStore?.PitchResponse
      );

      return (
        <span style={{ color: ChartsColor[index] }}>
          {filterStore?.PitchResponse?.charAt(0)?.toUpperCase() +
            filterStore?.PitchResponse?.substring(1)}
        </span>
      );
    }
  };

  const fetchData = async (modifiedFilterStore) => {
    try {
      setIsDisableApplyButton(true);
      setIsTableLoad(true);
      setTableData([]);
      const ApplicationData = await getDetailedApplicationData(
        getCurrentPageAllFiltersFromFilterStore(
          modifiedFilterStore,
          isInternalUser,
          PAGES.EMPLOYEE_BRANDING
        ),
        Clients,
        isPresentationEnabled
      );
      setTableData(ApplicationData.data.data.reports.applicants.data);
      setLastAppliedFilterstore(modifiedFilterStore);
      if (!locationStateUsed) {
        handleSetLocationUsed();
      }
    } catch (error) {
      // Handle error case
      console.log(error);
    } finally {
      setIsTableLoad(false);
      setIsDisableApplyButton(false);
    }
  };

  useEffect(() => {
    let payload = {
      ...filterStore,
    };
    if (!isEmpty(state?.customReportDetail) && !locationStateUsed) {
      if (
        !isEmpty(state.customReportDetail?.[FILTER_TYPES.LEFT_HAND_FILTERS])
      ) {
        payload = {
          ...overrideFilterStoreWithSavedFilters(filterStore),
        };
      } else {
        payload = {
          ...initialFilterStore,
        };
      }

      if (state?.customReportDetail?.currentGridState) {
        apiRef.current.restoreState(state.customReportDetail.currentGridState);

        //?Below logic bcz : columns orders are not restored by restoreState method
        if (
          state?.customReportDetail?.currentGridState?.columns?.orderedFields
        ) {
          setColumnsOrder((prevColumnsOrder) => {
            const orderedColumns =
              state.customReportDetail.currentGridState.columns.orderedFields;
            const copyPrevColumnsOrder = [...prevColumnsOrder];

            return orderArrayOfObjectSimilarToRefArrayOfKeys(
              copyPrevColumnsOrder,
              orderedColumns,
              "field"
            );
          });
        }
      }
    }
    setFilterStore(payload);
    fetchData(payload);

    return () => setTableData([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyFilterInEmployeeBranding]);

  useEffect(() => {
    const payload = !locationStateUsed
      ? {
          ...overrideFilterStoreWithSavedFilters(
            filterStoreToPassWhenTopFiltersChange
          ),
        }
      : getAllFlagC
      ? {
          ...filterStore,
        }
      : {
          ...filterStoreToPassWhenTopFiltersChange,
        };
    if (getAllFlagC) {
      setGetAllFlags((prev) => ({
        ...prev,
        getAllFlagC: false,
      }));
    }
    if (!initialRenderFlag) {
      fetchData(payload);
    }

    return () => setTableData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStore.startDate, filterStore.endDate, filterStore.PitchResponse]);

  useEffect(() => {
    if (!initialRenderFlag) {
      setColumnsOrder((prev) => {
        const temp = prev.map((item) =>
          getColumnsAfterChangeOrder(item, columns)
        );

        return temp;
      });
    }
    // eslint-disable-next-line
  }, [language]);

  const pageTitle = useMemo(() => {
    if (!isEmpty(state?.customReportDetail)) {
      return state.customReportDetail.title;
    }
    if (location.pathname === "/performancereport") {
      return performanceReportText.lineChartTitle[language];
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, location.pathname]);

  useEffect(() => {
    setInitialRenderFlag(false);
  }, []);

  return (
    <div className="card mt-4">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <h5 className="card-title">
            {/* {language !== "English" ? getChartName() : ""}{" "} */}
            {language !== "en"
              ? performanceReportText.applicantDetails[
                  filterStore?.PitchResponse
                ].ja
              : ""}{" "}
            {language === "en" ? "Detailed Breakdown of" : ""}{" "}
            {language === "en" ? getChartName() : ""}{" "}
            {language === "en"
              ? !isEmpty(state?.customReportDetail)
                ? state.customReportDetail.title
                : "Applicants"
              : ""}{" "}
            {/* {
        ? performanceReportText.detailedBreakdown.en
        : performanceReportText.detailedBreakdown.ja} */}
          </h5>

          <div className="d-flex gap-0 flex-shrink-0">
            <div
              className="action-button align-self-center"
              onClick={handleShowSaveAsModal}>
              <div className="m-0 flex-shrink-0">
                {performanceReportText.saveAs[language]}
              </div>
            </div>
            {(userGroup.includes("external") ||
              (userGroup.includes("internal") &&
                loggedInUserData.is_admin)) && (
              <div
                className="action-button align-self-center"
                onClick={handleShowExportModal}>
                <div className=" m-0">
                  {performanceReportText.export[language]}
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className="cstm-mui-datagrid"
          style={{ height: 500, width: "100%" }}>
          <DataGridPro
            rows={!isTableLoad ? ReportsData : []}
            columns={columnsWithOperators}
            onColumnOrderChange={(c) => {
              setColumnsOrder((prevCols) => {
                const newCols = [...prevCols];
                newCols.splice(c.oldIndex, 1);
                newCols.splice(c.targetIndex, 0, c.column);
                return newCols;
              });
            }}
            slotProps={{
              filterPanel: {
                sx: { maxWidth: "calc(90vw - 24px)" },
              },
            }}
            hideFooter
            // disableColumnFilter
            // disableColumnHeaderSorting
            initialState={{
              ...columns.initialState,
              columns: {
                ...columns.initialState?.columns,
                columnVisibilityModel: initialHiddenColumns,
              },
            }}
            loading={isTableLoad}
            onRowClick={(params) => {
              setSelectedRow(params.row);
              handleShowProfileModal();
            }}
            onColumnVisibilityModelChange={(hideColumns) => {
              const hideColsName = Object.entries(hideColumns)
                .filter(([key, value]) => value === false)
                ?.map((col) => col[0]);

              // const hideColumnsOrder = [...columnsOrder].filter(
              //   (col) => !hideColsName.includes(col.field)
              // );
              setHiddenColumns(hideColsName);
            }}
            localeText={convertGridColumnMenu(language)}
            rowsLoadingMode={"server"}
            apiRef={apiRef}></DataGridPro>
        </div>
      </div>
      <ModalBox
        show={showProfileModal}
        onHide={handleShowProfileModal}
        content={
          <CandidateProfileView
            // reportsData={ReportsData}
            closeModal={handleShowProfileModal}
            current={selectedRow}
            title={cardViewText.profileView[language]}
            apiRef={apiRef}
          />
        }
      />
      <ModalBox
        show={showExportModal}
        onHide={handleShowExportModal}
        title="Save As and Export File"
        content={
          <ExportModal
            setShowModal={setShowExportModal}
            columns={columnsOrder}
            fileName={pageTitle}
            apiRef={apiRef}
            hiddenColumns={hiddenColumns}
          />
        }
      />
      <ModalBox
        customClass="special-modal "
        show={showSaveAasModal}
        onHide={handleShowSaveAsModal}
        hideCloseIcon={true}
        content={
          <SaveAsModal
            reportName={pageTitle}
            handleCancel={handleShowSaveAsModal}
            apiRef={apiRef}
            extraTopFiltersToSave={{
              PitchResponse: filterStore.PitchResponse,
              startDate: filterStore.startDate,
              endDate: filterStore.endDate,
            }}
          />
        }
        title={<p className="fs-4 mb-0">Save Custom Report As</p>}
        size="sm"
      />
    </div>
  );
};

export default ApplicantDetailsTable;
