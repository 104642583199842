import MetricsLoader from "components/Loader/MetricsLoader";
import React from "react";
import { Col } from "react-bootstrap";

const DashboardCard = ({
  title,
  headerContent,
  children,
  className = "",
  mdSize,
  isLoading = false,
}) => {
  return (
    <Col md={mdSize ?? 6} sm={12} className="pt-3">
      <div className={`card h-100 ${className} position-relative`}>
        {isLoading && (
          <div
            className="position-absolute h-100 w-100 d-flex justify-content-center align-items-center z-2"
            style={{
              backgroundColor: isLoading ? "rgba(189, 189, 189, 0.1)" : "",
            }}>
            <MetricsLoader />
          </div>
        )}
        <div className="card-body d-flex flex-column gap-3 ">
          <div className="d-flex justify-content-between gap-2">
            <h5 className="card-title flex-shrink-1 lh-sm">{title}</h5>
            {headerContent}
          </div>
          {children}
        </div>
      </div>
    </Col>
  );
};

export default DashboardCard;
