import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import { formatDate, convertStageCodeToName } from "helpers/ReusableFunctions";
import { UserContext } from "context/UserContext";

const PitchHistory = ({
  pitchHistory = [],
  setActiveTab = () => {},
  setScrollToDetailSectionId = () => {},
}) => {
  const { language } = useContext(UserContext);

  return (
    pitchHistory.length > 0 && (
      <>
        <p className="profile_section-title"> Pitch History </p>
        <div className="card mb-4 user-profile" style={{ cursor: "pointer" }}>
          <div className="card-body text-muted">
            {pitchHistory.map((el, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    setActiveTab("Pitch History");
                    setScrollToDetailSectionId(el.position_id);
                  }}
                >
                  <Row>
                    <Col className="d-flex flex-column align-items-start">
                      <p className="profile_subsection-title mb-0">
                        {language === "ja"
                          ? el?.client_name_ja
                          : el?.client_name}
                      </p>
                      <Link
                        to={`https://esai.jobs/jobdetail/${el?.position_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {language === "ja"
                          ? el?.position_name_ja
                          : el?.position_name}
                      </Link>
                    </Col>

                    <Col className="d-flex flex-column align-items-end justify-content-start">
                      <p className="profile_subsection-subtitle mb-0 text-end">
                        {
                          //stage: any, pitch_response: any, language: any, renderForNull?: string
                          convertStageCodeToName(
                            el.stage,
                            el?.pitch_response?.response,
                            language,
                            ""
                          )
                        }
                      </p>
                      <p className="profile_subsection-body mb-0 text-end">
                        {formatDate(el?.updated_at)}
                      </p>
                      <p className="profile_subsection-body mb-0 text-end">
                        {el?.candidate_manager.first_name +
                          " " +
                          el?.candidate_manager.last_name}
                      </p>
                    </Col>
                  </Row>
                  {pitchHistory.length - 1 !== index && <hr />}
                </div>
              );
            })}
          </div>
        </div>
      </>
    )
  );
};

export default PitchHistory;
