import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";

import {
  FILTER_TYPES,
  PAGES,
  remotePolicy,
  status,
  visibility,
  lastThreeMonthsEnd,
  lastThreeMonthsStart,
  pieChartNameConvention,
  requiredLanguages,
} from "helpers/Constant";
import {
  convertDateToDateRangeSupportedFormat,
  getDefaultStages,
  getCurrentPageAllFiltersFromFilterStore,
  nameConvention,
  selectOptionConverter,
} from "helpers/ReusableFunctions";
import { UserContext } from "./UserContext";

const initialFiltersValue = {
  PitchResponse: "yes",
  Positions: [],
  Speciality: [],
  BusinessManagers: [],
  CandidateManagers: [],
  StageManagers: [],
  StageManagers1: [],
  StageManagers2: [],
  ClientManagers: [],
  Industry: [],
  startDate: lastThreeMonthsStart,
  endDate: lastThreeMonthsEnd,
  count: 0,
  unit: "quarter",
  RemotePolicy: [],
  Visibility: [],
  Status: [],
  RequiredLanguages: [],
  CompanyName: [],
  EnglishLevel: [],
  JapaneseLevel: [],
  Background: [],
  Tags: [],
  Title: [],
};

const initialApplyFiltersFlags = {
  applyFilterInActivePipeline: Math.random(),
  applyFilterInCandidatePipeline: Math.random(),
  applyFilterInDashboard: Math.random(),
  applyFilterInEmployeeBranding: Math.random(),
  applyFilterInSuccessKpi: Math.random(),
  applyFilterInRecentlyActiveCandidate: Math.random(),
  applyFilterInTimeToHire: Math.random(),
  applyFilterInJobs: Math.random(),
  applyFilterInCandidates: Math.random(),
};

const initialFiltersDropdownValue = {
  PositionsOption: [],
  SpecialityOption: [],
  BusinessManagerOptions: [],
  CandidateManagerOptions: [],
  StageManagersOptions: [],
  IndustryOption: [],
  ClientOptions: [],
  ClientManagerOptions: [],
  RemotePolicy: remotePolicy,
  Visibility: visibility,
  Status: status,
  RequiredLanguages: requiredLanguages,
  CompanyName: [],
  EnglishLevel: [],
  JapaneseLevel: [],
  Background: [],
  Tags: [],
  Title: [],
};

const initialFilterContextValue = {
  filterStore: {
    ...initialFiltersValue,
  },
  setFilterStore: () => {},
  isDisableApplyButton: false,
  setIsDisableApplyButton: () => {},
  loading: false,
  setLoading: () => {},
  dropdownData: {
    ...initialFiltersDropdownValue,
  },
  setDropdownData: () => {},
  Clients: [],
  setClients: () => {},
  yourIndustryId: "",
  setYourIndustryId: () => {},
  isPresentationEnabled: false,
  isInternalUser: false,
  setIsPresentationEnabled: false,
  clearFilterContext: () => {},
  externalClient: [],
  initialFilterStore: { ...initialFiltersValue },
  resetFiltersBeforeAndAfterPresentation: () => {},
  lastAppliedFilterstore: { ...initialFiltersValue },
  setLastAppliedFilterstore: () => {},
  leftHandFilterParams: {},
  setLeftHandFilterParams: () => {},
  applyFilterFlags: {
    ...initialApplyFiltersFlags,
  },
  changeApplyFilterFlag: () => {},
  overrideFilterStoreWithSavedFilters: () => {},
  filterStoreToPassWhenTopFiltersChange: { ...initialFiltersValue },
};

export const FilterContext = createContext(initialFilterContextValue);

export const FilterProvider = ({ children }) => {
  const haveDefaultStages = ["/candidatepipelinereport"];
  const haveDefaultCountOne = ["/successkpireport", "/timetohirereport"];
  const haveDefaultWeekTime = ["/recentlyactivecandidatesreport"];

  const { userGroup } = useContext(UserContext);
  const externalClient = JSON.parse(sessionStorage.getItem("externalClient"));

  const [filterStore, setFilterStore] = useState(initialFiltersValue);
  const [lastAppliedFilterstore, setLastAppliedFilterstore] =
    useState(initialFiltersValue);
  const [dropdownData, setDropdownData] = useState(initialFiltersDropdownValue);
  const [loading, setLoading] = useState(false);
  const [Clients, setClients] = useState(externalClient ? externalClient : []);
  const [isDisableApplyButton, setIsDisableApplyButton] = useState(false);
  const [isPresentationEnabled, setIsPresentationEnabled] = useState(
    externalClient ? true : false
  );
  const [leftHandFilterParams, setLeftHandFilterParams] = useState({});
  const [applyFilterFlags, setApplyFilterFlags] = useState({
    ...initialApplyFiltersFlags,
  });
  const [yourIndustryId, setYourIndustryId] = useState("");
  const [candidateAccess, setCandidateAccess] = useState({
    job: {},
    candidate: {},
    openedTab: "",
  });

  const location = useLocation();
  const isInternalUser =
    userGroup.includes("internal") && !isPresentationEnabled;

  const filterStoreToPassWhenTopFiltersChange = {
    ...filterStore,
    ...Object.keys(leftHandFilterParams).reduce((finalObj, key) => {
      return {
        ...finalObj,
        [key]: lastAppliedFilterstore[key],
      };
    }, {}),
  };

  function changeApplyFilterFlag(key) {
    setApplyFilterFlags((prev) => ({
      ...prev,
      [key]: Math.random(),
    }));
  }

  function clearFilterContext() {
    setFilterStore(initialFiltersValue);
    setLastAppliedFilterstore(initialFiltersValue);
    setLoading(false);
    setDropdownData(initialFiltersDropdownValue);
    setClients([]);
    setIsPresentationEnabled(false);
  }

  function resetFiltersBeforeAndAfterPresentation() {
    setFilterStore(initialFiltersValue);
    setLastAppliedFilterstore(initialFiltersValue);
  }

  function getCurrentPageFilters() {
    let currentPath = location.pathname;

    switch (currentPath) {
      case "/activepipelinereport":
        return getCurrentPageAllFiltersFromFilterStore(
          filterStore,
          isInternalUser,
          PAGES.ACTIVE_PIPELINE
        );
      case "/candidatepipelinereport":
        return getCurrentPageAllFiltersFromFilterStore(
          filterStore,
          isInternalUser,
          PAGES.CANDIDATE_PIPELINE
        );
      case "/performancereport":
        return getCurrentPageAllFiltersFromFilterStore(
          filterStore,
          isInternalUser,
          PAGES.EMPLOYEE_BRANDING
        );
      case "/successkpireport":
        return getCurrentPageAllFiltersFromFilterStore(
          filterStore,
          isInternalUser,
          PAGES.SUCCESS_KPI
        );
      case "/recentlyactivecandidatesreport":
        return getCurrentPageAllFiltersFromFilterStore(
          filterStore,
          isInternalUser,
          PAGES.RECENTLY_ACTIVE_CANDIDATES
        );
      case "/timetohirereport":
        return getCurrentPageAllFiltersFromFilterStore(
          filterStore,
          isInternalUser,
          PAGES.TIME_TO_HIRE
        );
      default:
        return {};
    }
  }

  function overrideFilterStoreWithSavedFilters(fs) {
    const { startDate, endDate, ...restFilters } =
      location.state?.customReportDetail?.[FILTER_TYPES.LEFT_HAND_FILTERS];
    const initialPageFilters = {
      ...Object.keys(getCurrentPageFilters())?.reduce(
        (finalObj, key) => ({
          ...finalObj,
          [key]: initialFiltersValue[key],
        }),
        {}
      ),
    };

    const modifiedStartDate = startDate
      ? convertDateToDateRangeSupportedFormat(startDate)
      : initialFiltersValue.startDate;
    const modifiedEndDate = endDate
      ? convertDateToDateRangeSupportedFormat(endDate)
      : initialFiltersValue.endDate;

    const final_fs = {
      ...fs,
      ...initialPageFilters,
      startDate: modifiedStartDate,
      endDate: modifiedEndDate,
      ...restFilters,
    };
    return final_fs;
  }

  //set default industry comes from backend
  useEffect(() => {
    if (isEmpty(filterStore.Industry) && yourIndustryId) {
      setFilterStore((prevFilterStore) => ({
        ...prevFilterStore,
        Industry: selectOptionConverter([yourIndustryId])[0],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStore.Industry]);

  useEffect(() => {
    const currentDate = new Date();

    const last_week_start_date = convertDateToDateRangeSupportedFormat(
      new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000)
    );
    const last_week_end_date = convertDateToDateRangeSupportedFormat(
      new Date(currentDate.getTime() - 1 * 24 * 60 * 60 * 1000)
    );
    last_week_end_date.setHours(23, 59, 59);

    const location_donut_data = location.state?.donutData;
    const location_filters = location.state?.filters;

    let defaultFilterStore = {
      ...initialFiltersValue,
      ...(haveDefaultStages.includes(location.pathname)
        ? {
            StageManagers: getDefaultStages(),
          }
        : {}),
      ...(haveDefaultCountOne.includes(location.pathname)
        ? {
            count: 1,
          }
        : {}),
      ...(location_donut_data
        ? {
            PitchResponse: nameConvention(
              pieChartNameConvention,
              location_donut_data
            )?.value,
          }
        : {}),
      ...(!isEmpty(location_filters)
        ? {
            ...location_filters,
          }
        : {}),
      ...(haveDefaultWeekTime.includes(location.pathname)
        ? {
            startDate: last_week_start_date,
            endDate: last_week_end_date,
          }
        : {}),
    };

    if (
      isEmpty(location.state?.customReportDetail) &&
      !location.state?.isPrevLeader &&
      !location.state?.isNavigateToLeaderboard &&
      !location.state?.filters &&
      !location.state?.donutData
    ) {
      setFilterStore(() => ({
        ...defaultFilterStore,
      }));
      setLastAppliedFilterstore(() => ({
        ...defaultFilterStore,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <FilterContext.Provider
      value={{
        filterStore,
        setFilterStore,
        isDisableApplyButton,
        setIsDisableApplyButton,
        loading,
        setLoading,
        dropdownData,
        setDropdownData,
        Clients,
        setClients,
        yourIndustryId,
        setYourIndustryId,
        isInternalUser,
        isPresentationEnabled,
        setIsPresentationEnabled,
        clearFilterContext,
        externalClient,
        initialFilterStore: initialFiltersValue,
        resetFiltersBeforeAndAfterPresentation,
        lastAppliedFilterstore,
        setLastAppliedFilterstore,
        leftHandFilterParams,
        setLeftHandFilterParams,
        applyFilterFlags,
        changeApplyFilterFlag,
        overrideFilterStoreWithSavedFilters,
        filterStoreToPassWhenTopFiltersChange,
        candidateAccess,
        setCandidateAccess,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
