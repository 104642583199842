import React, { useContext, useEffect, useMemo, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import {
  gridFilteredSortedRowEntriesSelector,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import { CsvBuilder } from "filefy";

import "../PipelineReport.scss";
import SelectUI from "components/Select/SelectUI";
import { languageShortAppOptions } from "helpers/Constant";
import { UserContext } from "context/UserContext";
import {
  convertStageCodeToName,
  formatDate,
  getColumnsAfterChangeOrder,
} from "helpers/ReusableFunctions";
import { getColumnsEnJaLabelsWithFields } from "helpers/getTableColumns";

const ExportModal = ({
  setShowModal,
  columns = [],
  fileName = "",
  apiRef,
  hiddenColumns = [],
}) => {
  const rows = useGridSelector(apiRef, gridFilteredSortedRowEntriesSelector);
  const { language } = useContext(UserContext);
  const initialLanguage = languageShortAppOptions.find(
    (item) => item.value === language
  );
  const initialFormData = {
    fileName: fileName,
    language: initialLanguage.value,
    tableColumns: [],
    tableColumnsHeaders: [],
  };
  const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage);
  const [formData, setFormData] = useState(initialFormData);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const finalRows = rows.map(({ id, model }) => {
    let copyModel = {
      ...model,
    };

    if (model?.current_stage || typeof model?.current_stage === "undefined") {
      copyModel = {
        ...copyModel,
        current_stage: convertStageCodeToName(
          model?.current_stage,
          model?.app_position?.pitch_response?.response,
          formData.language,
          "New Candidate"
        ),
      };
    }
    if (typeof model?.last_activity_type !== "undefined") {
      copyModel = {
        ...copyModel,
        last_activity_type: convertStageCodeToName(
          model?.last_activity_type,
          model?.app_position?.pitch_response?.response,
          formData.language,
          "New Candidate"
        ),
      };
    }
    if (model?.last_activity_date) {
      copyModel = {
        ...copyModel,
        last_activity_date: formatDate(model.last_activity_date),
      };
    }

    return {
      id,
      copyModel,
    };
  });

  const getLanguageColumns = useMemo(
    () => (columns, language) => {
      return columns.map((column) => {
        const element = getColumnsEnJaLabelsWithFields(language)?.find(
          (el) => el.field === column.field
        );
        return {
          label: element?.label ? element?.label : column.headerName,
          value: column.field,
        };
      });
    },
    []
  );

  const [modalColumnsOrder, setModalColumnOrder] = useState(
    columns.filter((col) => !hiddenColumns.includes(col.field))
  );

  const [convertedColumns, setConvertedCols] = useState(
    getLanguageColumns(modalColumnsOrder, initialFormData.language)
  );

  const changeColumnsOrderName = (col) => {
    switch (col) {
      case "first_name":
        return "kanji_last";
      case "last_name":
        return "kanji_first";
      case "kanji_first":
        return "last_name";
      case "kanji_last":
        return "first_name";
      default:
        return col;
    }
  };

  const handleExportInternaly = (fileName, columns) => {
    //?. uncomment if want to  Export Data With MUI function but there is displayed field name as columns Label
    // const csvExportOptions = {
    //   fields: columnsToExport,
    //   fileName: fileName,
    // };
    // apiRef.current.exportDataAsCsv(csvExportOptions);

    //?.make comment if want field_name as in export report because there is displayed translated Label on export report.
    const columnTitles = columns.map((column) => column.label);

    const csvData = finalRows
      ?.map((el) => el.copyModel)
      .map((rowData) => {
        return columns.map((column) => rowData[column.value]);
      });

    // const blob = new Blob(["\uFEFF" + csvData], {
    //   type: "text/csv; charset=utf-18",
    // });
    const builder = new CsvBuilder(`${fileName}.csv`)
      .setColumns(columnTitles)
      .addRows(csvData)
      .exportFile();

    return builder;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};

    // Perform form validation

    if (!formData.fileName.trim()) {
      newErrors.fileName = "Required";
    }

    if (!formData.language.trim()) {
      newErrors.language = "Required";
    }
    if (formData.tableColumns.length === 0) {
      newErrors.tableColumns = "Required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsLoading(true);
    try {
      handleExportInternaly(formData.fileName, formData.tableColumnsHeaders);
    } catch (error) {
      console.log(error);
    } finally {
      // Reset the form
      setFormData(initialFormData);
      setIsLoading(false);
      setShowModal(false);
      setErrors({});
    }
  };

  const selectedColumnsHandler = (e) => {
    setSelectedOptions(e);
  };
  const selectedLanguageHandler = (e) => {
    setFormData((prevFormData) => ({ ...prevFormData, language: e.value }));

    const columnsOrder = modalColumnsOrder.map((item) =>
      getColumnsAfterChangeOrder(item, columns)
    );

    setModalColumnOrder(columnsOrder);
    setConvertedCols(getLanguageColumns(columnsOrder, e.value));
    setSelectedLanguage(e);
  };

  const handleSelectAll = () => {
    setSelectedOptions(convertedColumns);
  };
  const handleDeselectAll = () => {
    setSelectedOptions([]);
  };
  const toggleSelectAll = () => {
    if (selectedOptions.length === convertedColumns.length) {
      handleDeselectAll();
    } else {
      handleSelectAll();
    }
  };

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      tableColumns: selectedOptions?.map((obj) => obj.value),
      tableColumnsHeaders: selectedOptions,
    }));
  }, [selectedOptions]);

  useEffect(() => {
    const selectedFieldsvalue = selectedOptions?.map((col) => col.value);
    if (selectedFieldsvalue) {
      const updatedOrder = selectedFieldsvalue.map((col) =>
        changeColumnsOrderName(col)
      );

      const updatedSelectedOptions = convertedColumns?.filter((col) =>
        updatedOrder.includes(col?.value)
      );

      setSelectedOptions(updatedSelectedOptions);
    }
    // eslint-disable-next-line
  }, [selectedLanguage]);

  return (
    <div className="export-card">
      {" "}
      <form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <div className="user-label">
              File Name{" "}
              {errors.fileName && (
                <span className="error">{errors.fileName}</span>
              )}
            </div>
            <input
              type="text"
              name="fileName"
              value={formData.fileName}
              onChange={handleInputChange}
            />

            <br />
          </Col>
          <Col md={6}>
            <div className="user-label">
              Language{" "}
              {errors.language && (
                <span className="error">{errors.language}</span>
              )}
            </div>
            <SelectUI
              className="add-export-select mt-1"
              options={languageShortAppOptions}
              selectedValue={selectedLanguageHandler}
              value={selectedLanguage}
            />
            <br />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className="user-label">
              Columns{" "}
              {errors.tableColumns && (
                <span className="error">{errors.tableColumns}</span>
              )}
            </div>
            <Row>
              <Col md={8} lg={10}>
                <SelectUI
                  className="add-export-select"
                  options={convertedColumns}
                  value={selectedOptions}
                  isMulti={true}
                  selectedValue={selectedColumnsHandler}
                />
              </Col>
              <Col md={4} lg={2}>
                <div
                  onClick={toggleSelectAll}
                  className="action-button-outline mt-2 mt-md-0 justify-content-center">
                  {selectedOptions.length === convertedColumns.length
                    ? "Deselect All"
                    : "Select All"}
                </div>
              </Col>
            </Row>
          </Col>

          <Col md={12} className="user-card-button">
            <button
              type="submit"
              className="action-button"
              disabled={isLoading}>
              {isLoading ? (
                <div>
                  <Spinner
                    animation="border"
                    size="sm"
                    className="text-white"
                    style={{ width: "1rem" }}
                  />
                </div>
              ) : (
                <div className="m-0">Download</div>
              )}
            </button>
            <button
              type="button"
              className="action-button-outline"
              onClick={() => setShowModal(false)}>
              <div className="m-0">Cancel</div>
            </button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default ExportModal;
