import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";

import { UserContext } from "context/UserContext";
import { cardViewText } from "helpers/Localization";

const splitString = (str) => {
  return str.split("\n");
};

const WorkHistory = ({ data = {}, basicActive = "" }) => {
  const { language } = useContext(UserContext);
  return (
    <>
      <div className="card mb-4">
        <div className="card-body">
          <Row className="work-history align-items-center">
            <Col sm={3}>
              <div className="mb-0 profile_subsection-dim_title ">
                {cardViewText.workHistory[language]}
              </div>
            </Col>
            <Col sm={9}>
              {data?.work_history ? (
                <ul className="mb-0 profile_subsection-body">
                  {splitString(data?.work_history).map((point, index) => (
                    <li
                      key={index}
                      className={`${
                        point === "" ? "list-type-none" : ""
                      } profile_subsection-body`}
                    >
                      {point.trim()}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-center mb-0">Unknown</p>
              )}
            </Col>
          </Row>
          <hr />
          <Row className="align-items-center">
            <Col sm={3}>
              <div className="mb-0 profile_subsection-dim_title">
                {cardViewText.education[language]}
              </div>
            </Col>
            <Col sm={9}>
              {data?.education_history ? (
                <ul className="mb-0">
                  <li className="profile_subsection-body">
                    {data?.education_history}
                  </li>
                </ul>
              ) : (
                <p className="text-center mb-0">Unknown</p>
              )}
            </Col>
          </Row>
          {basicActive === "Candidate Information" && (
            <>
              <hr />
              <Row className="align-items-center">
                <Col sm={3}>
                  <div className="mb-0 profile_subsection-dim_title">Bio</div>
                </Col>
                <Col sm={9}>
                  {data?.bio ? (
                    <ul className="mb-0">
                      <li className="profile_subsection-body">{data?.bio}</li>
                    </ul>
                  ) : (
                    <p className="text-center mb-0">Unknown</p>
                  )}
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default WorkHistory;
