import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";

import { cardViewText } from "helpers/Localization";
import { UserContext } from "context/UserContext";

const TagsSection = ({ data, showTags = false }) => {
  const { language } = useContext(UserContext);
  return (
    <div className="card mt-4 mb-4 lg-0">
      <div className="card-body">
        {showTags && (
          <Row className="mb-3">
            <Col>
              <ul className="list-group list-group-flush rounded-3 px-0 ">
                <li className=" d-flex flex-wrap justify-content-between align-items-center ">
                  <div className="profile_subsection-subtitle mb-2">Tags</div>
                  <div className="d-flex flex-wrap gap-1 ">
                    {data?.tags?.map((el, index, arr) => {
                      return (
                        <div
                          className="m-0 profile_subsection-body"
                          key={index}>
                          {`${el[0].toUpperCase() + el.slice(1)}${
                            arr.length - 1 !== index ? ", " : ""
                          }`}
                        </div>
                      );
                    })}
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <ul className="list-group list-group-flush rounded-3 px-0 mx-0">
              <li className=" d-flex flex-wrap justify-content-between align-items-center">
                <div className="profile_subsection-subtitle mb-2">
                  {cardViewText.languages[language]}
                </div>
                <div className="d-flex flex-wrap gap-2 flex-shrink-0">
                  <div className="badge rounded-pill">
                    English {data?.english}
                  </div>
                  <div className="badge rounded-pill">
                    Japanese {data?.japanese}
                  </div>
                </div>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TagsSection;
