import Submenu from "components/SubMenu/Submenu";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import JobDetailsTab from "./JobDetailsTab";
import { getJobDetails, getPitchedCandidates } from "services/FetchApiData";
import TargetedCandidatesTab from "./TargetedCandidatesTab";
import PitchedCandidatesTab from "./PitchedCandidatesTab";
import { FilterContext } from "context/FilterContext";
import moment from "moment";

const JobDetailsModalBody = ({
  currentData = {},
  setCurrentData = () => {},
  loading = false,
  setLoading = () => {},
}) => {
  const initialOpenedTab = "Job Details";
  const dataFetch = useRef(false);
  const pitchedCandidatesFetch = useRef(false);
  const { candidateAccess } = useContext(FilterContext);

  const [basicActive, setBasicActive] = useState(initialOpenedTab);
  const [pitchedCandidates, setPitchedCandidates] = useState([]);
  const [isPitchedCandidatesLoading, setIsPitchedCandidatesLoading] =
    useState(false);

  const layoutProps = {
    loading,
    basicActive,
    currentData,
  };

  const tabs = [
    {
      title: initialOpenedTab,
      content: (
        <JobDetailsTab
          {...layoutProps}
          totalPitchedCandidates={pitchedCandidates?.length}
        />
      ),
    },
    {
      title: "Targeted Candidates",
      content: <TargetedCandidatesTab {...layoutProps} />,
    },
    {
      title: "Pitched Candidates",
      content: (
        <PitchedCandidatesTab
          {...layoutProps}
          loading={isPitchedCandidatesLoading}
          pitchedCandidates={pitchedCandidates}
        />
      ),
    },
  ];

  const fetchJobDetails = useCallback(
    async (position_id) => {
      if (!position_id || dataFetch.current) return;
      try {
        setLoading(true);
        dataFetch.current = true;
        const response = await getJobDetails(position_id);
        const jobData = response?.data?.data?.positions?.client_job_detail;
        setCurrentData((prev) => ({
          ...prev,
          ...jobData,
          description: jobData?.description
            ? JSON.parse(jobData?.description)
            : null,
          tech_stack: jobData?.tech_stack
            ? JSON.parse(jobData?.tech_stack)
            : null,
        }));
      } catch (error) {
        // Handle error case
        console.log(error);
      } finally {
        setLoading(false);
        dataFetch.current = false;
      }
    },
    [setCurrentData, setLoading]
  );

  const fetchPitchedCandidates = useCallback(async (position_id) => {
    if (!position_id || pitchedCandidatesFetch.current) return;
    try {
      setIsPitchedCandidatesLoading(true);
      pitchedCandidatesFetch.current = true;
      const response = await getPitchedCandidates(position_id);
      setPitchedCandidates(
        response?.data?.data?.reports?.applicants?.data?.map((el) => ({
          ...el,
          age: el?.birthday ? moment().diff(el?.birthday, "years") : "",
        }))
      );
    } catch (error) {
      // Handle error case
      console.log(error);
    } finally {
      setIsPitchedCandidatesLoading(false);
      pitchedCandidatesFetch.current = false;
    }
  }, []);

  useEffect(() => {
    if (candidateAccess?.openedTab) {
      setBasicActive(candidateAccess?.openedTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchJobDetails(currentData?.position_id);
    fetchPitchedCandidates(currentData?.position_id);
  }, [currentData?.position_id, fetchJobDetails, fetchPitchedCandidates]);

  return (
    <Submenu
      tabs={tabs}
      basicActive={basicActive}
      setBasicActive={setBasicActive}
    />
  );
};

export default memo(JobDetailsModalBody);
