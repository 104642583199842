import React, { memo } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const JobDetailsModalHeader = ({
  title = "",
  currentData = {},
  currentIndex = 0,
  setCurrentIndex = () => {},
  reportsData = [],
  closeModal = () => {},
  loading = false,
}) => {
  const lastIndex = reportsData?.length - 1;

  const handleBack = () => {
    setCurrentIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => (prev === lastIndex ? prev : prev + 1));
  };

  return (
    <div className="w-100 d-flex justify-content-between flex-wrap gap-2 gap-lg-0 position-relative p-3 pb-0">
      <button
        className="d-block d-sm-none btn btn-sm btn-dark position-absolute p-2 m-0 bg-transparent link-hover shadow-sm rounded-circle"
        style={{
          color: "#6754e2",
          top: "-20px",
          right: "-20px",
        }}
        onClick={closeModal}
      >
        <AiOutlineClose style={{ fontSize: "25px", fontWeight: "bolder" }} />
      </button>
      <h4>{title}</h4>
      {!!reportsData.length && (
        <div className="scroll d-flex align-items-center flex-shrink-0 justify-content-center float-start float-sm-none flex">
          <h4>
            <IoIosArrowBack
              onClick={() => handleBack()}
              style={{
                cursor: "pointer",
                pointerEvents: loading ? "none" : "all",
                color:
                  currentData.position_id === reportsData[0].position_id ||
                  loading
                    ? "lightgray"
                    : "black",
              }}
            />
            <IoIosArrowForward
              onClick={() => handleNext()}
              style={{
                cursor: "pointer",
                pointerEvents: loading ? "none" : "all",
                color:
                  currentData.position_id ===
                    reportsData[lastIndex].position_id || loading
                    ? "lightgray"
                    : "black",
              }}
            />
          </h4>
          <span>
            {currentIndex + 1} / {reportsData.length}
          </span>
        </div>
      )}
    </div>
  );
};

export default memo(JobDetailsModalHeader);
