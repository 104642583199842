import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import { Row } from "react-bootstrap";
import {
  DataGridPro,
  getGridNumericOperators,
  getGridStringOperators,
  useGridApiRef,
} from "@mui/x-data-grid-pro";

import { FilterContext } from "context/FilterContext";
import { UserContext } from "context/UserContext";
import ExportModal from "pages/PipelineReport/components/ExportModal";
import ModalBox from "components/Modal/ModalBox";
import PageTitle from "components/PageTitle/PageTitle";
import {
  APPLY_FILTER_PAGE,
  ChartsColor,
  FILTER_TYPES,
  PAGES,
  PitchDeclinedReasonChoices,
  PitchResponseChoices,
  differentReportsPath,
  haveLeaderboardAccess,
  internalReportsPath,
  lastThreeMonthsEnd,
  lastThreeMonthsStart,
} from "helpers/Constant";
import {
  calculateAge,
  calculateMetricsForTimeToHire,
  calculateStatsForSuccessKpi,
  convertGridColumnMenu,
  countCurrentStages,
  formatDate,
  getColumnsAfterChangeOrder,
  convertStageCodeToName,
  orderArrayOfObjectSimilarToRefArrayOfKeys,
  reformatString,
  getCurrentPageAllFiltersFromFilterStore,
  groupingCandidatesByCM,
  groupingCandidatesByBM,
  selectOptionConverter,
  customStageColumnSorting,
} from "helpers/ReusableFunctions";
import { cardViewText, performanceReportText } from "helpers/Localization";
import DashboardCard from "components/Dashboard/DashboardCard";
import ProfileModalContent from "components/CandidateProfileModalContent/ProfileModalContent";
import CustomFilter from "components/Reports/CustomFilter";
import SaveAsModal from "./components/SaveAsModal";
import FilterNav from "components/FilterNav/FilterNav";
import {
  getActiveApplicantData,
  getCandidatePipelineData,
  getRecentlyActiveCandidatesList,
  getSuccessKPI,
  getSuccessKPICandidatesList,
  getTimeToHire,
  getTimeToHireCandidatesList,
} from "services/FetchApiData";
import SuccessKpiStats from "./components/SuccessKpiStats";
import ButtonUI from "components/UI/Button/ButtonUI";
import TimeToHireStats from "./components/TimeToHireStats";
import TopFilters from "./components/TopFilters";
import { AppContext } from "context/AppContext";

const PipelineReport = () => {
  const haveStageWiseCountsTopMetric = [
    "/candidatepipelinereport",
    "/recentlyactivecandidatesreport",
  ];

  const {
    setFilterStore,
    initialFilterStore,
    filterStore,
    Clients,
    setLastAppliedFilterstore,
    applyFilterFlags: {
      applyFilterInActivePipeline,
      applyFilterInCandidatePipeline,
      applyFilterInSuccessKpi,
      applyFilterInTimeToHire,
      applyFilterInRecentlyActiveCandidate,
    },
    changeApplyFilterFlag,
    setIsDisableApplyButton,
    loading,
    setLoading,
    leftHandFilterParams,
    isPresentationEnabled,
    overrideFilterStoreWithSavedFilters,
    filterStoreToPassWhenTopFiltersChange,
    isDisableApplyButton,
    isInternalUser,
    yourIndustryId,
  } = useContext(FilterContext);
  const { language, userGroup, loggedInUserData } = useContext(UserContext);
  const { setCandidatesGroupedByBM, setCandidatesGroupedByCM } =
    useContext(AppContext);

  const [selectedRow, setSelectedRow] = useState(null);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showSaveAasModal, setShowSaveAsModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [initialRenderFlag, setInitialRenderFlag] = useState(true);
  const [metricsData, setMetricsData] = useState([]);
  const [successMetricsData, setSuccessMetricsData] = useState({});
  const [successKpiCandidatesData, setSuccessKpiCandidatesData] = useState([]);
  const [timeToHireLineData, setTimeToHireLineData] = useState([]);
  const [timeToHireMetricsData, setTimeToHireMetricsData] = useState([]);
  const [timeToHireCandidatesData, setTimeToHireCandidatesData] = useState([]);
  const [recentlyActiveCandidatesData, setRecentlyActiveCandidatesData] =
    useState([]);
  const [candidatePipelineData, setCandidatePipelineData] = useState([]);
  const [activePipelineData, setActivePipelineData] = useState([]);
  const [successKpiLineData, setSuccessKpiLineData] = useState([]);
  const [ReportsData, setReportsData] = useState([]);
  const [isLoadingTableData, setIsLoadingTableData] = useState(false);
  const [getAllFlags, setGetAllFlags] = useState({
    getAllFlagA: false,
  });
  const [applySavedFilter, setApplySavedFilter] = useState(false);
  const [leaderboardAccessIds, setLeaderboardAccessIds] = useState({
    cm_ids: [],
    bm_ids: [],
  });
  const [pagination, setPagination] = useState({ limit: 100, offset: 0 });

  const apiRef = useGridApiRef();
  const location = useLocation();
  const state = location.state;

  const sortableColumn = true;
  const pinnableColumn = true;
  const addInternalUserPrivateSocialLinkColumns =
    internalReportsPath.includes(location.pathname) ||
    (["/successkpireport"].includes(location.pathname) && isInternalUser);

  const filterOperators = getGridStringOperators().filter(
    ({ value }) => !["isEmpty", "isNotEmpty", "isAnyOf"].includes(value)
  );

  const isPaging = useMemo(
    () =>
      ["/candidatepipelinereport", "/activepipelinereport"].includes(
        location.pathname
      ),
    [location.pathname]
  );

  const handleShowExportModal = () => {
    setShowExportModal((current) => !current);
  };

  const handleShowSaveAsModal = () => {
    setShowSaveAsModal((current) => !current);
  };

  const handleShowProfileModal = () => {
    setShowProfileModal((current) => !current);
  };

  const renderSocialLinksActions = (params) => {
    return (
      <Link to={params.value} target="_blank" rel="noreferrer">
        {params.value}
      </Link>
    );
  };

  const makeReportsData = () => {
    const reportsData = [
      ...(location.pathname === "/timetohirereport"
        ? timeToHireCandidatesData
        : location.pathname === "/candidatepipelinereport"
        ? candidatePipelineData
        : location.pathname === "/successkpireport"
        ? successKpiCandidatesData
        : location.pathname === "/recentlyactivecandidatesreport"
        ? recentlyActiveCandidatesData
        : activePipelineData),
    ]?.map((user, index) => {
      if (
        [...internalReportsPath.concat(differentReportsPath)].includes(
          location.pathname
        ) &&
        isInternalUser
      ) {
        setLeaderboardAccessIds((prev) => ({
          cm_ids: [...prev.cm_ids, user.app_position?.candidate_manager?.id],
          bm_ids:
            location.pathname !== "/recentlyactivecandidatesreport"
              ? [...prev.bm_ids, user.app_position?.business_manager?.id]
              : [],
        }));
      }

      return {
        id: index + 1,
        age: +calculateAge(user.birthday),
        // workhistory: stringSplit(user, 'prospects.workhistory'),
        initial_response: reformatString(
          PitchResponseChoices[user.app_position?.pitch_response.response]
        ),
        response_reason: reformatString(
          PitchDeclinedReasonChoices[user.app_position?.pitch_response.reason]
        ),
        candidate_manager: `${
          user.app_position?.candidate_manager?.first_name ?? ""
        } ${user.app_position?.candidate_manager?.last_name ?? ""}`,
        business_manager:
          user.app_position?.business_manager?.first_name +
          " " +
          user.app_position?.business_manager?.last_name,
        ...(Array.isArray(user.app_position?.stage_history) &&
        user.app_position?.stage_history.some(
          (data) => data.stage === user.app_position?.current_stage
        )
          ? user.app_position?.stage_history
              .filter((data) => data.stage === user.app_position?.current_stage)
              .map((s, i, arr) => ({
                text: Array.isArray(s.comments)
                  ? [...s?.comments].sort(
                      (a, b) =>
                        new Date(b.created_at).getMilliseconds() -
                        new Date(a.created_at).getMilliseconds()
                    )[0].text
                  : "",
                other_applications: s?.other_applications,
              }))[0]
          : {
              text: "",
              other_applications: "",
            }),
        client_name: user.app_position?.client_name,
        current_stage: user.app_position?.current_stage,
        elapsed_time: `${user?.time_diff?.days}D ${user?.time_diff?.hours}H ${user?.time_diff?.minutes}M`,
        focus: user.app_position?.focus,
        app_position_name: user.app_position?.position_name,
        role: user.app_position?.role,
        last_activity_date: new Date(user.last_activity_date),
        last_activity_type: user.last_activity_type,
        ...user,
      };
    });

    setReportsData(reportsData);
  };

  const getAllDataHandler = () => {
    setGetAllFlags({
      getAllFlagA: true,
    });
    const resetFilters = {
      ...filterStore,
      ...(location.pathname === "/successkpireport"
        ? { StageManagers: [] }
        : {
            StageManagers1: [],
            StageManagers2: [],
          }),
      ...Object.keys(leftHandFilterParams).reduce((finalObj, key) => {
        return {
          ...finalObj,
          [key]: [],
        };
      }, {}),
      startDate: lastThreeMonthsStart,
      endDate: lastThreeMonthsEnd,
      count: 1,
      unit: "quarter",
      Industry: yourIndustryId
        ? selectOptionConverter([yourIndustryId])[0]
        : [],
    };
    setFilterStore({
      ...resetFilters,
    });
    // changeApplyFilterFlag(APPLY_FILTER_PAGE.success_kpi);
    setLastAppliedFilterstore({ ...resetFilters });
  };

  const getExtraTopFilters = () => {
    return location.pathname === "/successkpireport"
      ? {
          startDate: filterStore.startDate,
          endDate: filterStore.endDate,
          StageManagers: filterStore.StageManagers,
          ...(!isInternalUser
            ? {
                Industry: filterStore.Industry,
              }
            : {}),
        }
      : location.pathname === "/timetohirereport"
      ? {
          startDate: filterStore.startDate,
          endDate: filterStore.endDate,
          StageManagers1: filterStore.StageManagers1,
          StageManagers2: filterStore.StageManagers2,
        }
      : location.pathname === "/recentlyactivecandidatesreport" ||
        location.pathname === "/candidatepipelinereport"
      ? {
          startDate: filterStore.startDate,
          endDate: filterStore.endDate,
        }
      : {};
  };

  const customElapsedTimeSorting = (v1, v2) => {
    let d1, h1, m1, d2, h2, m2;

    [d1, h1, m1] = v1.split(" ");
    [d2, h2, m2] = v2.split(" ");

    if (parseInt(d1) === parseInt(d2)) {
      if (parseInt(h1) === parseInt(h2)) {
        if (parseInt(m1) === parseInt(m2)) {
          return 0;
        } else {
          return parseInt(m1) - parseInt(m2);
        }
      } else {
        return parseInt(h1) - parseInt(h2);
      }
    } else {
      return parseInt(d1) - parseInt(d2);
    }
  };

  const japaneseName = [
    {
      field: "kanji_last",
      headerName: performanceReportText.lastNameJ[language],
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "kanji_first",
      headerName: performanceReportText.firstNameJ[language],
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
  ];

  const englishName = [
    {
      field: "first_name",
      headerName: performanceReportText.firstName[language],
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "last_name",
      headerName: performanceReportText.lastName[language],
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
  ];

  const columns = [
    {
      field: "id",
      headerName: performanceReportText.id[language],
      width: 30,
    },
    ...(["/recentlyactivecandidatesreport"].includes(location.pathname)
      ? [
          {
            field: "last_activity_date",
            headerName: "Date of Last Activity",
            width: 200,
            sortable: sortableColumn,
            pinnableColumn: pinnableColumn,
            filterOperators: filterOperators,
            renderCell: ({ value }) => {
              return formatDate(value);
            },
            valueGetter: ({ value }) => {
              return new Date(value);
            },
          },
          {
            field: "last_activity_type",
            headerName: "Type of Last Activity",
            width: 200,
            sortable: sortableColumn,
            pinnableColumn: pinnableColumn,
            filterOperators: filterOperators,
            valueGetter: ({ row }) => {
              return convertStageCodeToName(
                row?.last_activity_type,
                row?.app_position?.pitch_response.response,
                language,
                "New Candidate"
              );
            },
            sortComparator: customStageColumnSorting,
          },
        ]
      : []),
    ...(internalReportsPath
      .concat(differentReportsPath)
      .includes(location.pathname) && isInternalUser
      ? [
          {
            field: "candidate_manager",
            headerName: "Candidate Manager",
            width: 200,
            sortable: sortableColumn,
            pinnable: pinnableColumn,
            filterOperators: filterOperators,
          },
          ...(location.pathname !== "/recentlyactivecandidatesreport"
            ? [
                {
                  field: "business_manager",
                  headerName: "Business Manager",
                  width: 200,
                  sortable: sortableColumn,
                  pinnable: pinnableColumn,
                  filterOperators: filterOperators,
                },
              ]
            : []),
          {
            field: "client_name",
            headerName: "Client",
            width: 150,
            sortable: sortableColumn,
            pinnable: pinnableColumn,
            filterOperators: filterOperators,
          },
        ]
      : []),
    {
      field: "app_position_name",
      headerName: "Position",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },

    ...(language === "ja" ? japaneseName : englishName),
    ...(location.pathname === "/timetohirereport"
      ? [
          {
            field: "elapsed_time",
            headerName: "Elapsed Time",
            width: 200,
            sortable: sortableColumn,
            pinnableColumn: pinnableColumn,
            filterOperators: filterOperators,
            sortComparator: customElapsedTimeSorting,
          },
        ]
      : []),
    {
      field: "current_stage",
      type: "string",
      headerName: "Stage",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      valueGetter: ({ row }) => {
        return convertStageCodeToName(
          row?.current_stage,
          row?.app_position?.pitch_response.response,
          language,
          "New Candidate"
        );
      },
      sortComparator: customStageColumnSorting,
    },
    ...(!["/recentlyactivecandidatesreport"].includes(location.pathname)
      ? [
          {
            field: "last_activity_date",
            headerName: "Date of Last Activity",
            width: 200,
            sortable: sortableColumn,
            pinnableColumn: pinnableColumn,
            filterOperators: filterOperators,
            renderCell: ({ value }) => {
              return formatDate(value);
            },
          },
        ]
      : []),
    {
      field: "current_employer",
      headerName: performanceReportText.employer[language],
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "current_title",
      headerName: performanceReportText.title[language],
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "initial_response",
      headerName: performanceReportText.initialResponse[language],
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "response_reason",
      headerName: "Reason",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "role",
      headerName: "Role",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "focus",
      headerName: "Focus",
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "salary",
      headerName: performanceReportText.salary[language],
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      type: "number",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "salary_breakdown",
      headerName: performanceReportText.salaryBreakdown[language],
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "english",
      headerName: performanceReportText.english[language],
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      type: "number",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "japanese",
      headerName: performanceReportText.japanese[language],
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      type: "number",
      align: "left",
      headerAlign: "left",
    },
    ...(addInternalUserPrivateSocialLinkColumns
      ? [
          {
            field: "outreach_url",
            headerName: "Outreach",
            width: 200,
            sortable: sortableColumn,
            pinnable: pinnableColumn,
            filterOperators: filterOperators,
          },
          {
            field: "zoho_url",
            headerName: "ZOHO",
            width: 200,
            sortable: sortableColumn,
            pinnable: pinnableColumn,
            filterOperators: filterOperators,
          },
          {
            field: "trello_url",
            headerName: "Trello",
            width: 200,
            sortable: sortableColumn,
            pinnable: pinnableColumn,
            filterOperators: filterOperators,
          },
        ]
      : []),
    ...(language === "en" ? japaneseName : englishName),
    {
      field: "linkedin_url",
      headerName: performanceReportText.linkedinUrl[language],
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      renderCell: renderSocialLinksActions,
    },
    {
      field: "twitter_url",
      headerName: performanceReportText.twitterUrl[language],
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      renderCell: renderSocialLinksActions,
    },
    {
      field: "facebook_url",
      headerName: performanceReportText.facebookUrl[language],
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      renderCell: renderSocialLinksActions,
    },
    {
      field: "github_url",
      headerName: performanceReportText.githubUrl[language],
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
      renderCell: renderSocialLinksActions,
    },
    {
      field: "birthday",
      headerName: "Birth Date",
      // headerName: performanceReportText.id[language],
      // width: 30,
    },
    {
      field: "age",
      headerName: performanceReportText.age[language],
      width: 150,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "education_history",
      headerName: performanceReportText.educationHistory[language],
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "work_history",
      headerName: performanceReportText.workHistory[language],
      width: 500,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    ...(addInternalUserPrivateSocialLinkColumns
      ? [
          {
            field: "text",
            headerName: "Notes",
            width: 500,
            sortable: sortableColumn,
            pinnableColumn: pinnableColumn,
            filterOperators: filterOperators,
          },
          {
            field: "other_applications",
            headerName: "Other Applications",
            width: 500,
            sortable: sortableColumn,
            pinnableColumn: pinnableColumn,
            filterOperators: filterOperators,
          },
        ]
      : []),
  ];

  const initialHiddenColumns = {
    id: false,
    birthday: false,
    response_reason: false,
  };

  const [columnsOrder, setColumnsOrder] = useState(
    columns?.filter((col) => !initialHiddenColumns[col.field])
  );

  const [hiddenColumns, setHiddenColumns] = useState(
    Object.keys(initialHiddenColumns)
  );

  const columnsWithOperators = columnsOrder.map((col) => {
    if (col.type === "number") {
      return {
        ...col,
        filterOperators: [
          ...getGridNumericOperators()
            .filter((operator) => {
              if (operator.value === "=") operator.label = "equals";
              if (operator.value === ">") operator.label = "greater than";
              if (operator.value === "<") operator.label = "less than";
              return (
                operator.value === ">" ||
                operator.value === "<" ||
                operator.value === "="
              );
            })
            .map((operator) => ({
              ...operator,
              InputComponent: CustomFilter,
            })),
        ],
      };
    }

    const customFilterOperators = getGridStringOperators().map((operator) => ({
      ...operator,
      InputComponent: CustomFilter,
    }));

    return {
      ...col,
      filterOperators: customFilterOperators,
    };
  });

  const pageTitle = useMemo(() => {
    if (state?.prevTitle) {
      return state.prevTitle;
    } else if (!isEmpty(state?.customReportDetail)) {
      return state.customReportDetail.title;
    } else if (location.pathname === "/activepipelinereport") {
      return "Active Pipeline Report";
    } else if (location.pathname === "/candidatepipelinereport") {
      return "Candidate Pipeline Report";
    } else if (location.pathname === "/successkpireport") {
      return "Success KPI";
    } else if (location.pathname === "/recentlyactivecandidatesreport") {
      return "Recently Active Candidates";
    } else if (location.pathname === "/timetohirereport") {
      return "Time to Hire";
    } else return "No Report Found";
    // eslint-disable-next-line
  }, [location]);

  const getMetricsData = useCallback(
    (data) => {
      const isRecentActive =
        location.pathname === "/recentlyactivecandidatesreport";
      const stages = isRecentActive ? countCurrentStages(data) : data;
      const metricsData = [
        {
          stage: "Pitching & Waiting",
          count: isRecentActive ? stages[1] + stages[2] : stages?.pitching,
        },
        {
          stage: "Ready for Introduction",
          count: isRecentActive ? stages[3] : stages?.ready_for_introduction,
        },
        {
          stage: "Resume Screening",
          count: isRecentActive ? stages[4] : stages?.resume_screening,
        },
        {
          stage: "Testing",
          count: isRecentActive ? stages[5] : stages?.testing,
        },
        {
          stage: "Active Interviews",
          count: isRecentActive
            ? stages[6] + stages[7] + stages[8] + stages[9]
            : stages?.active_interviews,
        },
        {
          stage: "Final Interviews",
          count: isRecentActive ? stages[10] : stages?.final_interviews,
        },
        {
          stage: "Offers",
          count: isRecentActive
            ? stages[11] + stages[20] + stages[90]
            : stages?.offers,
        },
      ];

      setMetricsData(metricsData);
      return metricsData;
    },
    [location.pathname]
  );

  const resetTimeToHireReportsAllData = () => {
    setTimeToHireLineData([]);
    setTimeToHireMetricsData([]);
    setTimeToHireCandidatesData([]);
    return;
  };

  const fetchActivePipelineData = async (paginationValues = pagination) => {
    if (isLoadingTableData) return;
    try {
      setIsDisableApplyButton(true);
      setIsLoadingTableData(true);
      setActivePipelineData([]);
      const ApplicationData = await getActiveApplicantData(
        getCurrentPageAllFiltersFromFilterStore(
          filterStore,
          isInternalUser,
          PAGES.ACTIVE_PIPELINE
        ),
        Clients,
        isPresentationEnabled,
        paginationValues
      );
      setActivePipelineData([
        ...activePipelineData,
        ...(ApplicationData.data.data.reports.active_pipeline.data ?? []),
      ]);
      setLastAppliedFilterstore(filterStore);
      setPagination({
        ...paginationValues,
        count:
          ApplicationData?.data?.data?.reports?.active_pipeline?.count || 0,
        hasMore:
          ApplicationData?.data?.data?.reports?.active_pipeline?.has_more,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingTableData(false);
      setIsDisableApplyButton(false);
    }
  };

  const fetchCandidatePipelineData = async (paginationValues = pagination) => {
    if (isLoadingTableData) return;
    try {
      setIsDisableApplyButton(true);
      setIsLoadingTableData(true);
      const ApplicationData = await getCandidatePipelineData(
        getCurrentPageAllFiltersFromFilterStore(
          filterStore,
          isInternalUser,
          PAGES.CANDIDATE_PIPELINE
        ),
        Clients,
        paginationValues
      );
      setCandidatePipelineData([
        ...candidatePipelineData,
        ...(ApplicationData.data.data.reports.active_pipeline.data ?? []),
      ]);
      getMetricsData(
        ApplicationData.data.data.dashboard.active_pipeline_counts
      );
      const res_data = ApplicationData.data.data.reports.active_pipeline.data;

      setCandidatesGroupedByCM(groupingCandidatesByCM(res_data));
      setCandidatesGroupedByBM(groupingCandidatesByBM(res_data));

      setLastAppliedFilterstore(filterStore);

      setPagination({
        ...paginationValues,
        count:
          ApplicationData?.data?.data?.reports?.active_pipeline?.count || 0,
        hasMore:
          ApplicationData?.data?.data?.reports?.active_pipeline?.has_more,
      });
    } catch (error) {
      // Handle error case
      console.log(error);
    } finally {
      setIsDisableApplyButton(false);
      setIsLoadingTableData(false);
    }
  };

  const fetchSuccessKpiData = async (fs) => {
    if (!fs.StageManagers?.value) return;
    try {
      setSuccessKpiLineData([]);
      setLoading(true);

      const response = await getSuccessKPI(
        getCurrentPageAllFiltersFromFilterStore(
          fs,
          isInternalUser,
          PAGES.SUCCESS_KPI
        ),
        Clients,
        isPresentationEnabled
      );
      const { metricsData, lineGraphData } = calculateStatsForSuccessKpi(
        response,
        filterStore.StageManagers?.value,
        language
      );
      setSuccessMetricsData(metricsData);
      setSuccessKpiLineData(lineGraphData);
      setLastAppliedFilterstore(fs);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSuccessKpiCandidatesData = async (fs) => {
    if (isEmpty(fs.StageManagers)) return;
    try {
      setSuccessKpiCandidatesData([]);
      setIsLoadingTableData(true);
      setIsDisableApplyButton(true);
      const response = await getSuccessKPICandidatesList(
        getCurrentPageAllFiltersFromFilterStore(
          fs,
          isInternalUser,
          PAGES.SUCCESS_KPI
        ),
        Clients,
        isPresentationEnabled,
        isInternalUser
      );
      setSuccessKpiCandidatesData(
        response.data.data.reports.success_kpi_candidates_list
      );
      const res_data = response.data.data.reports.success_kpi_candidates_list;
      setCandidatesGroupedByCM(groupingCandidatesByCM(res_data));
      setCandidatesGroupedByBM(groupingCandidatesByBM(res_data));
      setLastAppliedFilterstore(fs);
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisableApplyButton(false);
      setIsLoadingTableData(false);
    }
  };

  const fetchTimeToHireData = async (fs) => {
    if (
      isEmpty(fs?.StageManagers1?.value) ||
      isEmpty(fs?.StageManagers2?.value)
    )
      return;
    try {
      setLoading(true);
      setTimeToHireLineData([]);
      setTimeToHireMetricsData([]);
      const response = await getTimeToHire(
        getCurrentPageAllFiltersFromFilterStore(
          fs,
          isInternalUser,
          PAGES.TIME_TO_HIRE
        ),
        Clients,
        isPresentationEnabled
      );
      const { metricsData, lineGraphData } =
        calculateMetricsForTimeToHire(response);
      setTimeToHireMetricsData(metricsData);
      setTimeToHireLineData(lineGraphData);
      setLastAppliedFilterstore(fs);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTimeToHireCandidatesData = async (fs) => {
    if (isEmpty(fs.StageManagers1) || isEmpty(fs.StageManagers2)) return;
    try {
      setIsLoadingTableData(true);
      setIsDisableApplyButton(true);
      const response = await getTimeToHireCandidatesList(
        getCurrentPageAllFiltersFromFilterStore(
          fs,
          isInternalUser,
          PAGES.TIME_TO_HIRE
        ),
        Clients,
        isPresentationEnabled,
        isInternalUser
      );

      setTimeToHireCandidatesData(
        response.data.data.reports.time_to_hire_candidates_list
      );

      const res_data = response.data.data.reports.time_to_hire_candidates_list;

      setCandidatesGroupedByCM(groupingCandidatesByCM(res_data));
      setCandidatesGroupedByBM(groupingCandidatesByBM(res_data));

      setLastAppliedFilterstore(fs);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingTableData(false);
      setIsDisableApplyButton(false);
    }
  };

  const fetchRecentlyActiveCandidatesData = async (fs) => {
    try {
      setRecentlyActiveCandidatesData([]);
      setIsLoadingTableData(true);
      setIsDisableApplyButton(true);
      const response = await getRecentlyActiveCandidatesList(
        getCurrentPageAllFiltersFromFilterStore(
          fs,
          isInternalUser,
          PAGES.RECENTLY_ACTIVE_CANDIDATES
        ),
        Clients
      );
      setRecentlyActiveCandidatesData(
        response.data.data.reports.recently_active_candidates
      );

      const res_data = response.data.data.reports.recently_active_candidates;

      setCandidatesGroupedByCM(groupingCandidatesByCM(res_data));
      setCandidatesGroupedByBM([]);

      setLastAppliedFilterstore(fs);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingTableData(false);
      setIsDisableApplyButton(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(location.state?.customReportDetail)) {
      setApplySavedFilter(true);
    } else {
      setApplySavedFilter(false);
    }

    if (!initialRenderFlag) {
      handleFetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!initialRenderFlag) {
      setColumnsOrder((prev) => {
        const temp = prev.map((item) =>
          getColumnsAfterChangeOrder(item, columns)
        );
        return temp;
      });
    }
    // eslint-disable-next-line
  }, [language]);

  useEffect(() => {
    if (location.pathname === "/activepipelinereport" && !initialRenderFlag) {
      if (pagination?.offset === 0) {
        fetchActivePipelineData();
      } else {
        apiRef.current.setPage(0);
      }
    }
    return () => setActivePipelineData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyFilterInActivePipeline]);

  useEffect(() => {
    if (
      location.pathname === "/candidatepipelinereport" &&
      !initialRenderFlag
    ) {
      if (pagination?.offset === 0) {
        fetchCandidatePipelineData();
      } else {
        apiRef.current.setPage(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    applyFilterInCandidatePipeline,
    filterStore.startDate,
    filterStore.endDate,
  ]);

  useEffect(() => {
    const finalFilterStore =
      getAllFlags.getAllFlagA || applySavedFilter
        ? {
            ...filterStore,
          }
        : {
            ...filterStoreToPassWhenTopFiltersChange,
          };

    if (location.pathname === "/successkpireport") {
      fetchSuccessKpiData(finalFilterStore);
      fetchSuccessKpiCandidatesData(finalFilterStore);
    }
    return () => {
      setSuccessKpiLineData([]);
      setSuccessMetricsData([]);
      setSuccessKpiCandidatesData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterStore.startDate,
    filterStore.endDate,
    filterStore.StageManagers,
    filterStore.Industry,
    filterStore.unit,
    filterStore.count,
  ]);

  useEffect(() => {
    if (location.pathname === "/successkpireport") {
      fetchSuccessKpiData(filterStore);
      fetchSuccessKpiCandidatesData(filterStore);
    }
    return () => {
      setSuccessKpiLineData([]);
      setSuccessMetricsData([]);
      setSuccessKpiCandidatesData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyFilterInSuccessKpi, location]);

  useEffect(() => {
    if (location.pathname === "/timetohirereport") {
      fetchTimeToHireCandidatesData(filterStore);
      fetchTimeToHireData(filterStore);
    }
    return () => {
      setTimeToHireCandidatesData([]);
      setTimeToHireMetricsData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyFilterInTimeToHire, location]);

  useEffect(() => {
    const finalFilterStore =
      getAllFlags.getAllFlagA || applySavedFilter
        ? {
            ...filterStore,
          }
        : {
            ...filterStoreToPassWhenTopFiltersChange,
          };

    if (location.pathname === "/timetohirereport") {
      fetchTimeToHireCandidatesData(finalFilterStore);
      fetchTimeToHireData(finalFilterStore);
    }
    return () => {
      setTimeToHireCandidatesData([]);
      setTimeToHireMetricsData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location,
    filterStore.StageManagers2,
    filterStore.startDate,
    filterStore.endDate,
    filterStore.count,
    filterStore.unit,
    filterStore.Industry,
  ]);

  useEffect(() => {
    const finalFilterStore =
      getAllFlags.getAllFlagA || applySavedFilter
        ? {
            ...filterStore,
          }
        : {
            ...filterStoreToPassWhenTopFiltersChange,
          };
    if (
      location.pathname === "/recentlyactivecandidatesreport" &&
      !initialRenderFlag
    ) {
      fetchRecentlyActiveCandidatesData(finalFilterStore);
    }
    return () => setRecentlyActiveCandidatesData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStore.startDate, filterStore.endDate]);

  useEffect(() => {
    if (
      location.pathname === "/recentlyactivecandidatesreport" &&
      !initialRenderFlag
    ) {
      fetchRecentlyActiveCandidatesData(filterStore);
    }
    return () => setRecentlyActiveCandidatesData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyFilterInRecentlyActiveCandidate, location]);

  useEffect(() => {
    if (!isEmpty(state?.customReportDetail)) {
      if (
        !isEmpty(state.customReportDetail?.[FILTER_TYPES.LEFT_HAND_FILTERS])
      ) {
        setFilterStore((prevFilterStore) => {
          return overrideFilterStoreWithSavedFilters(prevFilterStore);
        });
      } else {
        setFilterStore(() => ({
          ...initialFilterStore,
        }));
      }

      if (state?.customReportDetail?.currentGridState) {
        apiRef.current.restoreState(state.customReportDetail.currentGridState);

        //?Below logic bcz : columns orders are not restored by restoreState method
        if (
          state?.customReportDetail?.currentGridState?.columns?.orderedFields
        ) {
          setColumnsOrder((prevColumnsOrder) => {
            const orderedColumns =
              state.customReportDetail.currentGridState.columns.orderedFields;
            const copyPrevColumnsOrder = [...prevColumnsOrder];

            return orderArrayOfObjectSimilarToRefArrayOfKeys(
              copyPrevColumnsOrder,
              orderedColumns,
              "field"
            );
          });
        }
      }
    }
    if (location.pathname === "/activepipelinereport") {
      changeApplyFilterFlag(APPLY_FILTER_PAGE.active_pipeline);
    }
    if (location.pathname === "/candidatepipelinereport") {
      changeApplyFilterFlag(APPLY_FILTER_PAGE.candidate_pipeline);
    }
    if (location.pathname === "/successkpireport") {
      changeApplyFilterFlag(APPLY_FILTER_PAGE.success_kpi);
    }
    if (location.pathname === "/timetohirereport") {
      changeApplyFilterFlag(APPLY_FILTER_PAGE.time_to_hire);
    }
    if (location.pathname === "/recentlyactivecandidatesreport") {
      changeApplyFilterFlag(APPLY_FILTER_PAGE.recently_active_candidate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    makeReportsData();

    return () => {
      setLeaderboardAccessIds({
        cm_ids: [],
        bm_ids: [],
      });
      setReportsData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    candidatePipelineData,
    activePipelineData,
    successKpiCandidatesData,
    timeToHireCandidatesData,
    recentlyActiveCandidatesData,
  ]);

  useEffect(() => {
    if (location.pathname === "/recentlyactivecandidatesreport") {
      getMetricsData(recentlyActiveCandidatesData);
    }
  }, [getMetricsData, location.pathname, recentlyActiveCandidatesData]);

  useEffect(() => {
    if (isEmpty(filterStore.StageManagers)) {
      setSuccessMetricsData([]);
      setSuccessKpiLineData([]);
      setSuccessKpiCandidatesData([]);
    }
  }, [filterStore.StageManagers]);

  useEffect(() => {
    if (
      isEmpty(filterStore.StageManagers1) ||
      isEmpty(filterStore.StageManagers2)
    ) {
      setTimeToHireMetricsData([]);
      setTimeToHireLineData([]);
      setTimeToHireCandidatesData([]);
    }
  }, [filterStore.StageManagers1, filterStore.StageManagers2]);

  useEffect(() => {
    if (!isEmpty(location.state?.filters)) {
      setFilterStore((prevFilterStore) => {
        return {
          ...prevFilterStore,
          ...location.state.filters,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setInitialRenderFlag(false);
  }, []);

  useEffect(() => {
    if (getAllFlags.getAllFlagA) {
      setGetAllFlags((prev) => ({
        ...prev,
        getAllFlagA: false,
      }));
    }
    if (applySavedFilter) {
      setApplySavedFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchData = (paging) => {
    switch (location.pathname) {
      case "/candidatepipelinereport":
        fetchCandidatePipelineData(paging);
        break;

      case "/activepipelinereport":
        fetchActivePipelineData(paging);
        break;

      default:
        break;
    }
  };

  const handlePaginationModelChange = ({ page }) => {
    const newPaging = {
      ...pagination,
      offset: pagination?.limit * page,
    };
    setPagination(newPaging);
    handleFetchData(newPaging);
  };

  return (
    <>
      <PageTitle
        title={pageTitle}
        back={performanceReportText.backToList[language]}
        allowLeaderboardAccess={
          haveLeaderboardAccess.includes(location.pathname) && isInternalUser
        }
        isDisabledToNavigateLeaderboard={
          isEmpty(ReportsData) || isDisableApplyButton
        }
        navigateLeaderboardFrom={location.pathname}
        leaderboardAccessIds={{
          cm_ids: [
            ...Array.from(
              new Set([...leaderboardAccessIds["cm_ids"]].filter((id) => !!id))
            ),
          ],
          bm_ids: [
            ...Array.from(
              new Set([...leaderboardAccessIds["bm_ids"]].filter((id) => !!id))
            ),
          ],
        }}
      />
      <FilterNav
        apiRef={apiRef}
        ResetComponent={() =>
          ["/successkpireport", "/timetohirereport"].includes(
            location.pathname
          ) ? (
            <ButtonUI
              outline="no-outline"
              title={"Reset"}
              disabled={loading || isLoadingTableData}
              onClick={() => getAllDataHandler()}
            />
          ) : (
            <></>
          )
        }
      />
      <TopFilters
        resetTimeToHireReportsAllData={resetTimeToHireReportsAllData}
      />
      {haveStageWiseCountsTopMetric.includes(location.pathname) && (
        <Row>
          <DashboardCard mdSize={12} isLoading={isLoadingTableData}>
            <div className="active-pipeline-count mb-5">
              <div className="d-flex justify-content-center gap-4 row">
                {metricsData.map((el, index) => {
                  const color = ChartsColor[index % 3];
                  return (
                    <div className="text-center col" key={index}>
                      <h1 className="card-title" style={{ color }}>
                        {el.count}
                      </h1>
                      <h5> {el?.stage} </h5>
                    </div>
                  );
                })}
              </div>
            </div>
          </DashboardCard>
        </Row>
      )}
      {location.pathname === "/successkpireport" && (
        <SuccessKpiStats
          stageSelected={
            cardViewText?.[filterStore?.StageManagers?.value]?.[language] ?? ""
          }
          lineData={successKpiLineData}
          loading={loading}
          metricsData={successMetricsData}
          isInternalUser={isInternalUser}
        />
      )}
      {location.pathname === "/timetohirereport" && (
        <TimeToHireStats
          loading={loading}
          metricsData={timeToHireMetricsData}
          lineData={timeToHireLineData}
          isInternalUser={isInternalUser}
        />
      )}
      <div className="card mt-4">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <h5 className="flex-shrink-1">List of Candidates</h5>
            <div className="d-flex gap-0 flex-shrink-0">
              <div
                className="action-button align-self-center"
                onClick={handleShowSaveAsModal}
              >
                <div className=" m-0 flex-shrink-0">
                  {performanceReportText.saveAs[language]}
                </div>
              </div>
              {(userGroup.includes("external") ||
                (userGroup.includes("internal") &&
                  loggedInUserData.is_admin)) && (
                <div
                  className="action-button align-self-center"
                  onClick={handleShowExportModal}
                >
                  <div className="m-0">
                    {performanceReportText.export[language]}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className="cstm-mui-datagrid"
            style={{ height: 500, width: "100%" }}
          >
            <DataGridPro
              loading={isLoadingTableData}
              rows={ReportsData.length > 0 ? ReportsData : []}
              rowCount={pagination?.count}
              pagination
              pageSizeOptions={[pagination?.limit || 0]}
              onPaginationModelChange={handlePaginationModelChange}
              columns={columnsWithOperators}
              slotProps={{
                filterPanel: {
                  sx: { maxWidth: "calc(90vw - 24px)" },
                },
                pagination: {
                  backIconButtonProps: {
                    disabled: isLoadingTableData || !pagination?.offset,
                  },
                  nextIconButtonProps: {
                    disabled: isLoadingTableData || !pagination?.hasMore,
                  },
                },
              }}
              onColumnOrderChange={(c) => {
                setColumnsOrder((prevCols) => {
                  const newCols = [...prevCols];
                  newCols.splice(c.oldIndex, 1);
                  newCols.splice(c.targetIndex, 0, c.column);
                  return newCols;
                });
              }}
              initialState={{
                ...columns.initialState,
                sorting: {
                  sortModel: [{ field: "last_activity_type", sort: "asc" }],
                },
                columns: {
                  ...columns.initialState?.columns,
                  columnVisibilityModel: initialHiddenColumns,
                },
              }}
              onRowClick={(params) => {
                setSelectedRow(params.row);
                handleShowProfileModal();
              }}
              hideFooter={!isPaging}
              localeText={convertGridColumnMenu(language)}
              apiRef={apiRef}
              onColumnVisibilityModelChange={(hideColumns) => {
                const hideColsName = Object.entries(hideColumns)
                  .filter(([key, value]) => value === false)
                  ?.map((col) => col[0]);
                setHiddenColumns(hideColsName);
              }}
              rowsLoadingMode={"server"}
            />
          </div>
        </div>
      </div>
      <ModalBox
        show={showProfileModal}
        onHide={handleShowProfileModal}
        content={
          apiRef?.current?.state && (
            <ProfileModalContent
              closeModal={handleShowProfileModal}
              current={selectedRow}
              title={cardViewText.profileView[language]}
              apiRef={apiRef}
            />
          )
        }
      />
      <ModalBox
        show={showExportModal}
        onHide={handleShowExportModal}
        title="Export File"
        content={
          <ExportModal
            setShowModal={setShowExportModal}
            columns={columnsOrder}
            fileName={pageTitle}
            apiRef={apiRef}
            hiddenColumns={hiddenColumns}
          />
        }
      />
      <ModalBox
        customClass="special-modal"
        show={showSaveAasModal}
        onHide={handleShowSaveAsModal}
        hideCloseIcon={true}
        content={
          <SaveAsModal
            reportName={pageTitle}
            handleCancel={handleShowSaveAsModal}
            apiRef={apiRef}
            extraTopFiltersToSave={getExtraTopFilters()}
          />
        }
        title={<p className="fs-4 mb-0">Save Custom Report As</p>}
        size="sm"
      />
    </>
  );
};

export default PipelineReport;
