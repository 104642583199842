import React, { memo } from "react";
import { Render } from "@executivesearchai/notion-render-react";
import { Row, Col, Spinner } from "react-bootstrap";

const JobDetailsTab = ({
  loading = false,
  currentData = {},
  totalPitchedCandidates,
}) => {
  return (
    <div className="container job-details-card">
      {loading ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <Row className="h-full">
          <Col lg={4}>
            <div className="card mb-4 company-profile">
              <div className="card-body text-center">
                <div className="company-logo">
                  <img src={currentData?.client_logo} alt="client_logo" />
                </div>
                <h5 className="job_subsection-title">
                  {currentData?.client_name}
                </h5>
                <p className="job_subsection-body mb-2">
                  {currentData?.industry}
                </p>
                <p className="job_subsection-body mb-2">
                  {currentData?.company_stage}
                </p>
                <p className="job_subsection-body">{currentData?.location}</p>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body text-center">
                <p className="job_candidate-targets">
                  {currentData?.candidate_targets ?? "-"}
                </p>
                <h5 className="job_subsection-title mb-0">Candidate Targets</h5>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body text-center">
                <p className="job_candidate-targets">
                  {totalPitchedCandidates || "-"}
                </p>
                <h5 className="job_subsection-title mb-0">
                  Candidates Pitched
                </h5>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body text-center">
                <h5 className="job_subsection-title">Specifications</h5>
                <Row className="row-gap-2">
                  {currentData?.focus ? (
                    <Col xl={6}>
                      <div className="badge rounded-pill">
                        {currentData?.focus}
                      </div>
                    </Col>
                  ) : null}
                  {currentData?.required_languages ? (
                    <Col
                      xl={
                        currentData?.required_languages?.length >= 10 ? 12 : 6
                      }
                    >
                      <div className="badge rounded-pill">
                        {currentData?.required_languages} Required
                      </div>
                    </Col>
                  ) : null}
                  <Col xl={6}>
                    <div className="badge rounded-pill">
                      {currentData?.role}
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div className="badge rounded-pill">
                      {currentData?.remote_policy}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col lg={8}>
            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex gap-4">
                  <p className="job_subsection-title mb-0">
                    Job Name (English):
                  </p>
                  <p className="job_subsection-subtitle mb-0 job_name">
                    {currentData?.position_name}
                  </p>
                </div>
                <div className="d-flex gap-4">
                  <p className="job_subsection-title mb-0">
                    Job Name (Japanese):
                  </p>
                  <p className="job_subsection-subtitle mb-0 job_name">
                    {currentData?.position_name_ja}
                  </p>
                </div>
                <hr />
                <Row className="align-items-baseline">
                  <Col md="auto">
                    <p className="job_subsection-title mb-0">Job Category:</p>
                  </Col>
                  <Col md="auto">
                    <p className="job_subsection-subtitle mb-0 ">
                      {currentData?.role}
                    </p>
                  </Col>
                  {currentData?.focus ? (
                    <>
                      <Col md="auto">
                        <p className="job_subsection-title mb-0">Specialty:</p>
                      </Col>
                      <Col md="auto">
                        <p className="job_subsection-subtitle mb-0 ">
                          {currentData?.focus}
                        </p>
                      </Col>
                    </>
                  ) : null}
                </Row>
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="job_subsection-title">Job Description</h5>
                <div className="job_description">
                  <Render blocks={currentData?.description} />
                </div>
              </div>
            </div>
            {currentData?.tech_stack?.length ? (
              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="job_subsection-title">Tech Stack</h5>
                  <div className="job_description">
                    <Render blocks={currentData?.tech_stack} />
                  </div>
                </div>
              </div>
            ) : null}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default memo(JobDetailsTab);
