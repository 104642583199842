import { performanceReportText } from "./Localization";

export const getColumnsEnJaLabelsWithFields = (language) => {
  let current_stage_label =
    window.location.pathname === "/performancereport"
      ? "Latest Stage"
      : "Stage";

  const columns = [
    {
      field: "id",
      label: performanceReportText.id[language],
    },
    {
      field: "app_position_name",
      label: "Position",
    },
    {
      field: "kanji_last",
      label: performanceReportText.lastNameJ[language],
    },
    {
      field: "kanji_first",
      label: performanceReportText.firstNameJ[language],
    },
    {
      field: "current_stage",
      label: current_stage_label,
    },
    {
      field: "current_employer",
      label: performanceReportText.employer[language],
    },
    {
      field: "current_title",
      label: performanceReportText.title[language],
    },
    {
      field: "initial_response",
      label: performanceReportText.initialResponse[language],
    },
    {
      field: "response_reason",
      label: "Reason",
    },
    {
      field: "role",
      label: "Role",
    },
    {
      field: "focus",
      label: "Focus",
    },
    {
      field: "salary",
      label: performanceReportText.salary[language],
    },
    {
      field: "salary_breakdown",
      label: performanceReportText.salaryBreakdown[language],
    },
    {
      field: "english",
      label: performanceReportText.english[language],
    },
    {
      field: "japanese",
      label: performanceReportText.japanese[language],
    },
    {
      field: "first_name",
      label: performanceReportText.firstName[language],
    },
    {
      field: "last_name",
      label: performanceReportText.lastName[language],
    },
    {
      field: "linkedin_url",
      label: performanceReportText.linkedinUrl[language],
    },
    {
      field: "twitter_url",
      label: performanceReportText.twitterUrl[language],
    },
    {
      field: "facebook_url",
      label: performanceReportText.facebookUrl[language],
    },
    {
      field: "github_url",
      label: performanceReportText.githubUrl[language],
    },
    {
      field: "birthday",
      label: "Birth Date",
    },
    {
      field: "age",
      label: performanceReportText.age[language],
    },
    {
      field: "education_history",
      label: performanceReportText.educationHistory[language],
    },
    {
      field: "work_history",
      label: performanceReportText.workHistory[language],
    },
    {
      field: "elapsed_time",
      label: "Elapsed Time",
    },
    {
      field: "last_activity_date",
      label: "Date of Last Activity",
    },
    {
      field: "last_activity_type",
      label: "Type of Last Activity",
    },
    {
      field: "candidate_manager",
      label: "Candidate Manager",
    },
    {
      field: "business_manager",
      label: "Business Manager",
    },
    {
      field: "client_name",
      label: "Client",
    },
    {
      field: "outreach_url",
      label: "Outreach",
    },
    {
      field: "zoho_url",
      label: "ZOHO",
    },
    {
      field: "trello_url",
      label: "Trello",
    },
    {
      field: "text",
      label: "Notes",
    },
    {
      field: "other_applications",
      label: "Other Applications",
    },
  ];

  return columns;
};
