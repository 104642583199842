import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

import { UserContext } from "context/UserContext";
import { ChartsColor, barChartNoReasons } from "helpers/Constant";
import { barChartText } from "helpers/Localization";
import { FilterContext } from "context/FilterContext";

const CustomTooltip = ({ active, payload, label }) => {
  const { User, language, loggedInUserData, userGroup } =
    useContext(UserContext);
  const { isPresentationEnabled, Clients } = useContext(FilterContext);

  if (active && payload && payload.length) {
    return (
      <div className="bg-white d-flex flex-column gap-2 p-2 border">
        <span>{barChartText[barChartNoReasons[label]][language]}</span>
        {payload.map((item, i) => {
          if (item.dataKey === "company") {
            return (
              <span key={i} style={{ color: item.color }}>
                {userGroup.includes("internal") &&
                isPresentationEnabled &&
                Clients.value
                  ? `${Clients.label} : ${item.value}%`
                  : User?.signInUserSession?.idToken?.payload[
                      "custom:alt_org_name"
                    ]
                  ? language === "en"
                    ? `${User?.signInUserSession?.idToken?.payload["custom:alt_org_name"]} : ${item.value}%`
                    : `${barChartText[item.dataKey].ja} : ${item.value}%`
                  : `${loggedInUserData?.organization?.name} : ${item.value}%`}
              </span>
            );
          }
          return (
            <span key={i} style={{ color: item.color }}>
              {language === "en"
                ? `${item.dataKey[0].toUpperCase()}${item.dataKey.slice(1)} : ${
                    item.value
                  }%`
                : `${barChartText[item.dataKey].ja} : ${item.value}%`}
            </span>
          );
        })}
      </div>
    );
  }

  return null;
};

const BarRecharts = ({ detailedBarData, loading, dataKeys }) => {
  const location = useLocation();
  const { language } = useContext(UserContext);

  const formatReasons = (val) => {
    return barChartText[barChartNoReasons[val]][language];
  };

  return (
    <>
      {location.pathname === "/performancereport" ? (
        <ResponsiveContainer height={275} className={loading ? "d-grid" : ""}>
          {!loading ? (
            <BarChart
              width={500}
              height={275}
              data={detailedBarData}
              margin={{ left: 20, top: 20, bottom: 30 }}>
              <CartesianGrid
                strokeWidth="3 0"
                horizontal={true}
                vertical={false}
              />

              <XAxis
                dataKey="name"
                tickFormatter={(val) => formatReasons(val)}
                label={{
                  value: barChartText.reason[language],
                  position: "bottom",
                }}
              />
              <YAxis
                tickFormatter={(value) => `${value}%`}
                ticks={[0, 20, 40, 60, 80, 100]}
                label={{
                  value: barChartText.applicantRates[language],
                  angle: -90,
                  position: "insideBottomLeft",
                }}
              />
              <Tooltip
                cursor={{ fill: "transparent" }}
                content={<CustomTooltip />}
              />
              <Legend />
              {dataKeys.map((datakey, i) => (
                <Bar
                  key={datakey}
                  dataKey={datakey}
                  fill={ChartsColor[i]}
                  radius={[4, 4, 0, 0]}
                  barSize={20}
                />
              ))}
            </BarChart>
          ) : (
            <div className="d-flex justify-content-center align-items-center p-4">
              <Spinner animation="border" />
            </div>
          )}
        </ResponsiveContainer>
      ) : (
        <div
          style={{
            height: 270,
          }}
          className="d-flex flex-column justify-content-center text-center">
          <h2>Something amazing is in the works</h2>
          <h5 className="ms-5 me-5">
            We're excited to bring you a new report that will increase the
            performance of your interview rates.
          </h5>
        </div>
      )}
    </>
  );
};

export default BarRecharts;
